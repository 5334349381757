import React, {useState, useEffect} from 'react';
import {IonButton, IonCheckbox, IonContent, IonLabel, IonModal, IonSpinner, IonText, isPlatform} from "@ionic/react";
import {isEmpty} from "../../../../Utils/Utils";
import moment from "moment/moment";
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {ChangeLogin} from "../../../../redux/slices/currentUser/currentUserSlice";
import {getTraining} from "../../../../redux/slices/trainingData/trainingDataSlice";
import {updateDisponibility} from "../../../../redux/slices/disponibility/disponibility";
import {PlanGenerate} from "../../../../API/Training/PlanGenerate";
import {GetDisponibility} from "../../../../API/Disponibility/GetDisponibility";
import {useGetAllProductsQuery} from "../../../../redux/slices/ApiRedux/Vip/VipSlice";

const ProfilModal = ({showModal, setShowModal, currentDisponibility, numberOfRaces}) => {

    const history = useHistory()
    const [spin, setSpin] = useState(false)
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.currentUser);
    const disponibilityData = async () => {
        await axios
            .post(
                `${process.env.REACT_APP_STAGING_V2}/disponibility/foreach`,
                {
                    debug: false,
                    filter: [
                        {
                            field: "userId",
                            comparator: "==",
                            operand: [[currentUser.id]],
                        },
                    ],
                    fields: [],
                },
                {
                    headers: {
                        Authorization: currentUser.token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    dispatch(updateDisponibility({id: res.data.Data[0].id, disponibility: res.data.Data[0]}))
                    setShowModal(true)
                } else {

                }
            })
            .catch((err) => {
            });
    }

    useEffect(() => {

        disponibilityData()
    }, [])


    const {data: training, refetch} = useGetAllProductsQuery()

    const [
        practice_running_time_available_by_week,
        setPractice_running_time_available_by_week,
    ] = useState(numberOfRaces);
    const week = training.Data[0].Plan.TemporalPlanId

    const handleCountUp = () => {
        setPractice_running_time_available_by_week(
            practice_running_time_available_by_week + 1
        );
        if (practice_running_time_available_by_week >= 7) {
            return setPractice_running_time_available_by_week(
                practice_running_time_available_by_week
            );
        }
    };
    const handleCountDown = () => {
        setPractice_running_time_available_by_week(
            practice_running_time_available_by_week - 1
        );
        if (practice_running_time_available_by_week <= 1) {
            return setPractice_running_time_available_by_week(
                practice_running_time_available_by_week
            );
        }
    };


    const handleSubmit = () => {
        setSpin(true)
        axios
            .post(
                `${process.env.REACT_APP_STAGING_V2}/disponibility/update`,
                {
                    debug: false,
                    data: {
                        numberOfRaces: practice_running_time_available_by_week.toString(),
                        id: currentDisponibility.id,
                        userId: currentUser.id,
                        monday: currentDisponibility.monday,
                        tuesday: currentDisponibility.tuesday,
                        wednesday: currentDisponibility.wednesday,
                        thursday: currentDisponibility.thursday,
                        friday: currentDisponibility.friday,
                        saturday: currentDisponibility.saturday,
                        sunday: currentDisponibility.sunday,
                        week: week,
                    },
                },
                {
                    headers: {
                        Authorization: currentUser.token,
                    },
                }
            )
            .then(async (res) => {
                if (res.status === 200) {
                    await dispatch(GetDisponibility(currentUser))
                    await dispatch(PlanGenerate(currentUser))
                    refetch()
                    setSpin(false)
                    setShowModal(false)


                }
            })
            .catch((err) => console.error(err));
    };


    return (


        <div className="profilModal">

            {
                !isEmpty(currentDisponibility) && (
                    <>

                        <div className="profil_modal_content">
                            <IonButton
                                className="weekOfRaces_button"
                                onClick={handleCountDown}
                            >
                                -
                            </IonButton>

                            <h5

                                className="profil_modal_text">
                                {practice_running_time_available_by_week}


                            </h5>

                            <IonButton className="weekOfRaces_button" onClick={handleCountUp}>
                                +
                            </IonButton>
                        </div>

                        <IonButton onClick={handleSubmit} className={"profil_modal_button"}>
                            {spin ? <IonSpinner name="crescent"/> : "Modifier"}
                        </IonButton>
                    </>
                )
            }
        </div>

    );
};

export default ProfilModal;