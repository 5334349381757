import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import SkeletonTrainingWeek from "./SkeletonTrainingWeek/SkeletonTrainingWeek";
import TrainingWeekPlan from "./trainingWeekPlan/TrainingWeekPlan";

import moment from "moment/moment";
import axios from "axios";
import {getShowModal, getTraining} from "../../../redux/slices/trainingData/trainingDataSlice";
import {useHistory} from "react-router";
import FormSuccess from "../../Formulaire/FormContent/FormSuccess/FormSuccess";
import {ChangeLogin} from "../../../redux/slices/currentUser/currentUserSlice";
import {IonImg, IonText} from "@ionic/react";
import logoFlit from "../../../assets/FormulaireAssets/Ola como estas.gif";
import {isEmpty} from "../../../Utils/Utils";
import {trainingWeekPlan} from "../../../assets/db";
import FabButton from "../../../pages/FabButton";
import {useGetAllProductsQuery, useGetProductQuery} from "../../../redux/slices/ApiRedux/Vip/VipSlice";

const Home = ({trainingData}) => {


    const countTraining = useSelector(state => state.count);

    const history = useHistory();

    const showModal = useSelector(state => state.showModal)
    const currentUser = useSelector((state) => state.currentUser);

    const user = useSelector((state) => state.currentUser);
    const isRegister = useSelector(state => state.login.isRegister)
    const isLoggin = useSelector(state => state.login.isLoggin)
    const [data, setData] = useState([])
    const [mounted, setMounted] = useState(false);
    const dispatch = useDispatch()

    const showTheModal = async (temporalId) => {

        // temporalId = temporalPlanId in trainingWeekPlan, see the reference below ↓↓↓
        // get the week number with the temporalPlanId, have to split the temporalPlanId
        // use moment js to have the date (sunday of the temporalPlanId week  at 8 am)
        const actualSunday = await moment()
            .year(
                parseInt(temporalId.toString().split("")[0] + "" + temporalId.toString().split("")[1] + "" + temporalId.toString().split("")[2] + "" + temporalId.toString().split("")[3])
            )
            .isoWeek(parseInt(temporalId.toString().split("")[4] + "" + temporalId.toString().split("")[5]))
            .endOf("isoWeek")
            .hour(8)
            .minute(0)
            .second(0);


        // if current time is after actualSunday , update redux for showModal
        if (moment() > actualSunday) {
            dispatch(getShowModal({showModal: true, afterRecalcul: false}))
        } else {
            dispatch(getShowModal({showModal: false}))
        }


    }

    useEffect(() => {
        showTheModal(trainingData[0].Plan.TemporalPlanId)
    }, [])


    return (
        <>
            {
                isRegister === true && isLoggin === false ?

                    <FormSuccess
                        user={user}
                    />
                    :

                    <>
                        {
                            !isEmpty(trainingData) && (
                                <>

                                    <TrainingWeekPlan
                                        training={trainingData}
                                        user={user}
                                        mounted={mounted}
                                        setMounted={setMounted}
                                        count={countTraining.value}

                                    />
                                </>
                            )
                        }

                    </>

            }


        </>
    )
}
export default Home;
