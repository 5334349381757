import {
    IonContent,
    IonHeader,
    IonPage,

    IonToolbar,
} from "@ionic/react";
import Home from "../components/App/Home/Home";
import PedagogieIndex from "../components/App/Pedagogie/PedagogieIndex";
import React from "react";

const Tab3 = () => {
    return (
        <IonPage>
            <IonContent className="app_content">
                <PedagogieIndex/>
            </IonContent>
        </IonPage>
    );
};

export default Tab3
