// Copyright 2022 c4ffein
//
// Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated
// documentation files (the "Software"), to deal in the Software without restriction, including without limitation the
// rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to
// permit persons to whom the Software is furnished to do so, subject to the following conditions:
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the
// Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE
// WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR
// COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
// OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
// @ts-ignore

// Only 5 exported function here, every other info is available from stores, like available subscriptions etc
// initShopLogic, buyPackage, and subscribe works for both Android and iOS devices:
// we want to buy on the same platform we use, so ofc the available ids we have are still the ones we gonna use.
// The SubscriptionTermination functions are split between Apple and Google:
// We want to be able to cancel subscription linked to the account from another device.
// We can't for Apple actually, but it may be OK to cancel the Google one from an iOS frame.

// You shouldn't start using bought days ASAP. In the backend, you must make a difference betwwen the days you just
// bought, and that can be used whenever you want, and the days you consume one at the start of each day.
// You must pause this mechanism when an auto-renewable subscription is in effect.
// There are non-renewable subscriptions that are proposed by Apple to be used instead, but those aren't available with
// this plugin.

// This is a PoC right now obviously. While the functions will stay the same, the current error handling is very poor.
// Failures mid-logic could leave the statuses in a currently non-recoverable state.
// This doesn't seem wrong to the author of the plugin we have to use, more details below.

// TODO to adapt to Flit codebase:
// - replace fetch , there are 2, 1 in log, 1 in accept, instructions at each one
// - replace IAP_LOG_URL and IAP_ACCEPT_URL obviously
// - Make your own log function, better log device time
// - get hPackages and hSubscriptions from the backend. Warning:
//   - it must not contains infos like the price etc
//   - those infos must be obtained from the shops, as in the guidelines
//   - the plugin does that for you
// - There is a call to initInAppPurchase() in that code. You can remove that line and call it yourself,
//   as soon as Capacitor is ready.
// - in App.jsx
//   - `import { initShopLogic } from "./shops";`
//   - `initShopLogic();` ideally just before you hide the splashscreen

import {InAppPurchase2 as iap} from "@awesome-cordova-plugins/in-app-purchase-2";
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser';
import {store} from '../../../redux/redux';
import {setShop} from '../../../redux/slices/shop/shopSlice';
import {GetisVip} from "../../../redux/slices/currentUser/currentUserSlice";
import {Capacitor} from "@capacitor/core";

// const IAP_LOG_URL = "https://c1.p1.c4ffein.io/log/whatever/";  // Use .env and axios in production ofc
const IAP_ACCEPT_URL = `${process.env.REACT_APP_STAGING_V2}/payment/checkReceipt`;
const IAP_ACCEPT_URL_ANDROID = `${process.env.REACT_APP_STAGING_V2}/payment/googleReceipts`;
// Use .env and axios in production ofc

// Stupid remote log function that just ensures we add timing and iteration information in case multiple events are
// fired close. We can then rebuild the order on the backend when we want to read the logs.
// And on this client, it's fire and forget.
let lastLog = Date.now(), iLog = 0;
const log = dS => {

    const nLog = Date.now();
    if (nLog == lastLog) iLog++; else {
        lastLog = nLog;
        iLog = 0;
    }
    // .catch(e => {alert`uncaught`; alert(e)})  // What are we supposed to do anyway
    // The read error doesnt matter as it's only when reading resp that it happens, the backend has the data.
    // You should probably use axios or whatever is currently used in the app to make requests instead.
}
// lp and le are to shortcut to log product events, and errors, generated by IAP
// It's better to be sure to be able to debug problems that will happen in production
const lp = (event, product) => log({msg: "iap event", product, event});
const le = (event, error) => log({msg: "iap error", error, event});


// Algorithmic helpers
const oE = Object.entries;
const oFE = Object.fromEntries;
const oV = Object.values;

// We should get those data from our server instead of generating it here
// We'll use the same method buyPackage for both consumables and subscriptions.
// That's why internal ids of hPackages and hSubscriptions can't overlap.
/*const hPackages = oFE([1, 3, 4].map(*/
const hPackages = oFE([].map(  // testing with a missing day, as it is possible with AppStore
    v => [v, {id: `${v}_day_usage`, alias: `${v}du`, type: iap.CONSUMABLE}]
));
const hSubscriptions = {  // Non-renewing subscriptions
    flit1m: {id: 'flit1m', alias: '1ms', type: iap.PAID_SUBSCRIPTION},
    flit1y: {id: 'flit1y', alias: '1ys', type: iap.PAID_SUBSCRIPTION},
};

const dispatch = store.dispatch;  // We are outside components etc, cant use useDispatch

log({msg: 'starting new session'})


// Very bad plugin issues / pulls:
//   - #447: https://github.com/j3k0/cordova-plugin-purchase/issues/447 - see j3k0 comments. TL;DR:
//     - There is a buying queue on iOS that can get filled up, resulting in an unusable plugin.
//     - You can get in that plugin state through a bad implem of the product.verify method, but also #448.
//   - #448: https://github.com/j3k0/cordova-plugin-purchase/issues/448
//     - We can fill the buy queue, again, for reasons that shouldn't be considered edge cases.
//     - See #448 in rest of the code.
//   - #995: https://github.com/j3k0/cordova-plugin-purchase/issues/995 - the ready event does not fire anymore
//     - You HAVE to use .verify to not let the products in unusable states.
//     - This is not what was written in most of the documentation, keeping old exemples for the most part.
//     - https://github.com/j3k0/cordova-plugin-purchase/blob/master/doc/example.js : finish() without verify(),
//       can cause unintended consequences.
//     - How to fix in comment of the 12 Jul 2021
//   - #1330: https://github.com/j3k0/cordova-plugin-purchase/pull/1330
//     - "After trying to work my way to force this into the existing code-base,"
//       "I ended up concluding that it was never created with that intent, it involved too much hacks."
//       "My brain exploded after a couple of weeks."
//     - Ofc he isn't responsible for the initial codebase and it took him time to realise he had to start from scratch.
//   - #1331: https://github.com/j3k0/cordova-plugin-purchase/issues/1331
//     - https://github.com/j3k0/cordova-plugin-purchase/issues/1331#issuecomment-1260879751
//       "store.ready never triggers I've seen that happen when some custom receipt validator are used that don't fully"
//       "implement the protocol (but I think I've only seen that on iOS)."
//       Ofc misusing store.validator once can break the future flow. See "No receipt validator" later on.
// Tried improvements:
//   - Used a custom callback. Made the transactions in an unrecoverable state, used another implem to clean the queue.
//   - Used the paid service, with the rest of the code nearly as-is.
//     The app behaved the same, buying 2 times in a row didn't work, had to restart the app to get the approved event.
//     It is still safe that way : Android / iOS send again the events on app start while we didn't mark it as completed.
//   - Not calling verify at all : it didn't work better.
//     Reading https://purchase.cordova.fovea.cc/discover/micro-example you would expect it to work for buying twice.
//     But this is an Android demo, on iOS it doesn't work through those tests.
//     Wether by calling verify and finish in verified, or just finish in accepted.
//     We receive the second accepted event only on app restart.
//     And if we don't verify, actually, on app restart, we don't even receive the ready event.
//  - Keep track of last order type to check cancel event match, even if we shouldn't get a cancel for another event
//    Actually useless with that plugin...

// No receipt validator service defined through the plugin. Keeping those 2 exemples in case you want to try, but see #447.
// And #1331.
// - iap.validator = 'url';  // Needs to be IPv6 compatible, otherwise Apple may reject your app
// - iap.validator = (p, c) => { c(true, { transaction: "transaction success wip message" }); };
//   - This is what is recomended through the doc for callback usage, see
//     https://github.com/j3k0/cordova-plugin-purchase/blob/master/doc/api.md#validation-callback-example
//     "// your custom details will be merged into the product's transaction field"
//   - But actually this is WRONG. The callback needs to return the product, populating the transaction field itself.
//     See https://github.com/j3k0/cordova-plugin-purchase/blob/5d52fae81498887670af414f39edc69407e45cf2/src/js/validator.js#L304
//     - _validator is the internal function called instead of validator, as validator is optional.
//     - if store.validator is not defined, it directly calls callback(true, product)
//       So the callback expect the product itself and not only mergeable data
// This plugin is terrible, as if we don't return the expected data, and keep trying to buy, we can result in #447
// See the workaround in the approveHandler function.

// Receipts
// Apple app receipts are supposed to be available:
//   - since first app launch, at multiple events (but mainly app launch) in production
//   - only after first buy, and then on the same occasions than in production, in Testflight
// That's why you are spammed with events with the 'application' id after your first transaction.
// It's not a bug, it's a feature, nothing wrong with that in theory (except if the plugin handles those badly...)
// That's why we should only use those receipts when they are available, but maybe find a way to check if there are
// missing for users that are supposed to have done at least one transaction.
// The author recommended to verify() and finish() those, which we do
// - see https://github.com/j3k0/cordova-plugin-purchase/issues/1316

// This is recommended in the documentation,
// but was actually never called during my whole debug process...
iap.error(function (error) {
    log({msg: 'general iap error : ', error})
});


// SSOT - Single source of trust
// - We only use redux to share states to the rest of the app
// - The plugin is responsible for keeping track of merchant stores items, and their states (in theory).
//   We use it as the SSOT for the list of items.
// - localStatus is a local SSOT we use to keep track of the user initiating an order, and the plugin being ready.
//   We need to replicate it to redux at every change.

const localStatus = {ready: false, transacting: false};
// if subscription.state === 'owned' or subscription.state === 'approved', and the backend still not showing it as owned, show as processing.
const localSubs = {};

// Totally non-optimal to call the whole process again, but is it fast enough? Yes.
// We expect the reducer to only update provided keys in the dispatched dict, but that does not matter in that implem
// The ready argument is optional. This is the only accepted way to ready the Shop store.
const naiveSetShopStore = ready => {
    if (ready !== undefined) localStatus.ready = ready;
    dispatch(setShop({
        packages: oFE(oE(hPackages).map(([k, v]) => [k, {...v, type: iap.CONSUMABLE, ...iap.get(v.alias)}])),
        subscriptions: oFE(oE(hSubscriptions).map(([k, v]) => [k, {
            ...v,
            type: iap.PAID_SUBSCRIPTION, ...iap.get(v.alias)
        }])),
        ...localStatus,  // Also check all packages etc are in an ok for us state? Not with a plugin so poorly implemented
    }));
};

// You can access the transacting status directly from the SSOT if needed, so just need a function to check and update
// Returns true if we managed to update. We can directly use this function to stop if we didn't get the lock.
// No way to safely track orders, and .canPurchase can stay true after we order a product, so just use a global lock.
// In theory we should use a queue of all current transactions. Which the plugin doesn't expose ofc, #448, least worst.
const setShopTransacting = transacting => {
    if (transacting && localStatus.transacting) return false;
    localStatus.transacting = !!transacting;
    dispatch(setShop({...localStatus}));
    return true;
};


const approveHandler = (product) => {


    if (product.type === iap.PAID_SUBSCRIPTION) {
        if (store.getState().currentUser.id !== undefined) {
            log({msg: 'approved', product});


            if (Capacitor.getPlatform() === 'android') {
                fetch(IAP_ACCEPT_URL, {
                    method: 'POST',
                    body: JSON.stringify({
                        debug: false,
                        data: {
                            ds: product,
                            userId: store.getState().currentUser.id,
                            transactionId: product.transactions
                        }
                    })
                })
                    .then(function (response) {
                        if (response.ok) {
                            product.verify();
                            dispatch(GetisVip({isVip: true, isTrialPeriod: false}));
                        } else le("approve response not ok");
                    }).catch(e => {
                    le("caught approve url", e)
                });
            } else {
                fetch(IAP_ACCEPT_URL, {
                    method: 'POST',
                    body: JSON.stringify({
                        debug: false,
                        data: {
                            ds: product,
                            userId: store.getState().currentUser.id,
                            transactionId: product.transactions
                        }
                    })
                })
                    .then(function (response) {

                        if (response.ok) {
                            product.verify();
                            dispatch(GetisVip({isVip: true, isTrialPeriod: false}));
                        } else le("approve response not ok");
                    }).catch(e => {
                    le("caught approve url", e)
                });
            }


            // This is the workaround for #447, we don't rely on the plugin for product.verify(), but
            //   - We still need to check for receipt. We do it here so if it fails, we keep the approved state, and the event
            //     could be fired again, e.g. on app restart. It does, it seems, so we are safe.
            //   - We need to call .verify before finish, see #995. So we just log in back here, and call .verify then.

            // We want to finish and stop even if the receipt is refused by the backend.
            // The only condition for us to not finish here, is if the backend couldn't log the receipt.
            // Otherwise, we should consider it is now it's job to compute our different credits.


        }
    }

}

const updateProduct = product => {  // returns true if the product is expected, false otherwise

    if ((product.type === iap.CONSUMABLE && oV(hPackages).map(v => v.id).includes(product.id))
        || (product.type === iap.PAID_SUBSCRIPTION && oV(hSubscriptions).map(v => v.id).includes(product.id))
    ) {
        // Don't do that before reading : Exemple of another way to handle accept status.
        // BUT don't finish, maybe verify worflow could work, as finish on unverified product can cause problems, see #995.
        // if(product.loaded && product.valid && product.state === store.APPROVED) { product.finish(); }

        naiveSetShopStore();

        return true;
    }
    return false;
}


//if on an ios or android device, then get product info 
export function initInAppPurchase() {

    const platform = Capacitor.getPlatform()  // eslint-disable-line
    if ((platform !== 'ios') && (platform !== 'android')) {
        return false;
    }
    naiveSetShopStore();
    iap.verbosity = iap.log;
    try {
        log({msg: 'shop readying'});  // We want to know if readying fails without throwing exceptions, log before and after
        iap.ready(() => {
            log({msg: 'shop ready'});
            naiveSetShopStore(true);
        });
        iap.register([...oV(hSubscriptions)]);
        ([
            // Those are expected events, we'll take action, log either in function or here
            ["loaded", p => {
                updateProduct(p);
                lp("loaded", p);
            }], // When product data is loaded from the store
            ["updated", p => {
                updateProduct(p);
                lp("updated", p);
            }], // When any change occured to a producta
            ["approved", product => {
                approveHandler(product)
            }], // When a product order is approved.
            ["verified", product => {  // When receipt validation successful

                lp("verified", product);
                product.finish();  // Put consumables to their initial states, subs to owned, still recommended for all else
                setShopTransacting(false);// Race condition if finish is not immediate, not worse than the plugin code
            }],
            // Refund is just logged for now. It depends how you want to handle it in the backend.
            ["refunded", product => {
                lp("refunded", product);
            }],  // When an order is refunded by the user.
            // We just expect one transaction to be in progress, so, on user cance, we just re-enable the shop, and log.
            ["cancelled", product => {
                lp("cancelled", product);
                setShopTransacting(false);
            }],  // User cancelled an order.
            // expected events, but we handle those statuses another way, e.g. by doing the whole validation in the backend
            ["unverified", product => {
                lp("unverified", product);
            }],  // When receipt verification failed
            // Should only apply to subscriptions in our case, as we want to
            ["owned", product => {
                lp("owned", product);

            }],  // When a non-consumable product or subscription is owned.
            // unexpected events, still logging just in case
            ["expired", product => {
                lp("expired", product);
            }],  // When validation find a subscription to be expired
            ["downloading", (product, progress, time_remaining) => {  // When content download is started
                log({msg: "unhandled event", product, progress, time_remaining, event: "downloading"});
            }],
            ["downloaded", product => {
                lp("downloaded", product);
            }],  // When content download has completed
            // Global error. In doubt, don't release the shop transacting status.
            ["error", err => {
                le("error", err);
            }], // When an order failed. err is an error object
        ]).forEach(([action, callback]) => iap.when()[action](callback));

        iap.refresh();
    } catch (e) {
        log({msg: 'global ready catch', error: e});
    }
}

export function restorePurchases() {
    iap.refresh();
}


export function buyPackage(packageInternalId) {
    log({msg: 'buy intent'});  // If the backend doesn't receive the rest of the process or a cancel, there is a problem
    //
    const platform = Capacitor.getPlatform()  // eslint-disable-line
    if ((platform !== 'ios') && (platform !== 'android')) {
        return false;
    }

    // Next line totally unsafe since we have no way to know if there are blocked processes or stuck legit transactions
    // oV(hPackages).map(p => iap.get(p.id).finish());  // Try to finish all to fix the plugin queue problem?
    if (!setShopTransacting(true)) {
        log("BAD LOCK");
        return false;
    }

    try {
        iap.order(hSubscriptions[packageInternalId].alias)

        // This returns an empty object, we can't really keep track
    } catch (e) {  // we both need the try catch and the Promise catch

        log({eid: 'plc', error: {e, message: e.message, code: e.code}});
        // setShopTransacting(false)  // in theory, we could let the user retry, but we don't want the user to fill the
        // buying queue that this plugin can't handle, gonna let it in failed state. The user has to restart the app to try
        // again. Trying to fix that edge case could result in even worse consequences, see #448
    }
    return true;
}


export function askAppleSubscriptionTermination(subscriptionInternalId) {
    // We just redirect to the apple subscriptions as recommended by the Apple documentation
    // Print a warning on Android instead? This is the Apple way, this URL only works with MacOS, iOS, Windows with iTunes
    // See https://support.apple.com/en-us/HT202039
    // Apple doesn't let you stop the auto-renewing of subscriptions through its API contrarily to Google
    return InAppBrowser.create("https://apps.apple.com/account/subscriptions", '_system');
}

export function askGoogleSubscriptionTermination(subscriptionInternalId) {
    // TODO : use https://developers.google.com/android-publisher/api-ref/rest/v3/purchases.subscriptions/cancel from backend instead
    // This URL works from web, TODO verify it launches the correct intent on Android
    // Otherwise use e.g. https://www.npmjs.com/package/@awesome-cordova-plugins/web-intent
    return InAppBrowser.create("https://play.google.com/store/account/subscriptions", '_system');
    // You can open a webview on Apple instead, but check it is ok through dev contract : this is an external service so the rules are different
    //return InAppBrowser.create("https://play.google.com/store/account/subscriptions", '_blank');
}
