import {
    IonContent,
    IonPage,
    IonText,
    IonHeader,
    IonToolbar,
    IonImg,
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import Dispo from "../../../../Reusable/Dispo";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import moment from "moment";
import axios from "axios";
import logo from "../../../../../assets/FormulaireAssets/6.png";
import {updateDisponibility} from "../../../../../redux/slices/disponibility/disponibility";
import FooterForm from "../../../../Reusable/FooterForm";
import {ChangeLogin, currentUser} from "../../../../../redux/slices/currentUser/currentUserSlice";

const FormDisponibility = () => {
    const history = useHistory();
    const user = useSelector((state) => state.currentUser);
    const dispatch = useDispatch();
    const [week, setWeek] = useState();
    const dispo = useSelector(state => state.disponibility)
    const [disponibility, setDisponibility] = useState({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
    })


    useEffect(() => {
        if (moment() > moment().endOf("isoWeek").hour(8).minute(0).second(0)) {
            setWeek(moment().year() + "" + (moment().week() + 1));
        } else {
            if (moment().week() < 10) {
                let parse = `${moment().year()}0${moment().week()}`;
                setWeek(parse)
            } else {
                setWeek(moment().year() + "" + moment().week());
            }

        }
    })




    const handleSubmit = () => {



        if(Object.values(disponibility).filter(t=>t === false).length === 7) {
            alert("Tu dois au moins sélectionner un jour pour que ton plan d'entrainement soit calculé")
        }else{


            axios
                .post(`${process.env.REACT_APP_STAGING_V2}/disponibility/create`, {
                    data: {
                        userId: user.id,
                        numberOfRaces: dispo.numberOfRaces.toString(),
                        monday: disponibility.monday,
                        tuesday: disponibility.tuesday,
                        wednesday: disponibility.wednesday,
                        thursday: disponibility.thursday,
                        friday: disponibility.friday,
                        saturday: disponibility.saturday,
                        sunday: disponibility.sunday,
                        week: parseInt(week),
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(ChangeLogin({isLoggin: false, isRegister: true}));
                        history.push("/tabs/tab1");
                    }
                })
                .catch((err) => console.log(err));
        }


    };
    return (
        <IonPage>

            <IonContent className={"objectif content_form"}>
                <div className="howManyTimes_content div_principal">
                    <IonImg src={logo} className="howManyTimes_logo"/>
                    <div className={"howManyTimes_title_box"}>
                        <h1 className={"howManyTimes_title_vert title_form_fredoka"} style={{textAlign: "center"}}>Ma
                            1ère semaine</h1>
                        <h1 className={"howManyTimes_title_blue title_form_fredoka"}>d’entraînement</h1>
                    </div>

                    <h5 style={{textAlign: "center"}}>Quand est-ce que tu es disponible cette semaine ?</h5>
                    <div className="howManyTimes_disponibility_content">
                        <Dispo disponibility={disponibility} setDisponibility={setDisponibility}/>
                    </div>

                </div>

                <FooterForm text={"Finaliser"} onClick={handleSubmit}/>
            </IonContent>
        </IonPage>
    );
};

export default FormDisponibility;
