import React, { useEffect, useState } from "react";
import {
  IonBackButton,
  IonHeader,
  IonText,
  IonToolbar,
  IonPage,
  IonButtons,
  IonContent,
  IonSkeletonText,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonAvatar,
  IonThumbnail,
} from "@ionic/react";
import "./weekBilanAllure.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { changeNewPlan } from "../../../../redux/slices/newPlan/newPlanSlice";

const WeekBilan = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // ! Changement fait pas Titouan (?)
  // ? Ancienne version
  const [dataBilan, setDataBilan] = useState();

  // ? Nouvelle version
  const [questions, setQuestions] = useState();
  const [answers, setAnswers] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.currentUser);

  useEffect(() => {

    try{


    setIsLoading(true);
    setTimeout(() => {
      const LoadData = async () => {
        // ? Récupération des questions
        await axios
          .post(
            `${process.env.REACT_APP_STAGING_V2}/questions/forEach`,
            {
              debug: false,
              filter: [],
              fields: [],
            },
            {
              headers: {
                authorization: user.token,
              },
            }
          )
          .then((res) => {
            // setDataBilan(res.data)
            setQuestions(res.data);
          })
          .catch((err) => console.log(err));
        // ? Récupération des réponses
        await axios
          .post(
            `${process.env.REACT_APP_STAGING_V2}/answers/forEach`,
            {
              debug: false,
              filter: [],
              fields: [],
            },
            {
              headers: {
                authorization: user.token,
              },
            }
          )
          .then((res) => {
            // ? Récupérer les 5 premières questions
            // ? Car sur la V2 ce n'est plus le bon formatage de ma version V1'
            const size = 5;
            const items = res.data.Data.slice(0, size);
            setAnswers(items);
          })
          .catch((error) => console.log(error));
      };
      LoadData();
      setIsLoading(false);
    }, 1000);
    }catch (e) {
      console.log(e)
    }
  }, []);

  const handleChangeSlide = (title) => {
    dispatch(changeNewPlan({ question1: title }));
    history.push("/weekbilanvolume");
  };

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar mode="ios" className="app_toolbar">
          <IonButtons>
            <IonBackButton
              text=""
              defaultHref="/tabs/tab1"
              className="app_backButtons"
            ></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {isLoading ? (
          <>
            {" "}
            <div className="ion-padding custom-skeleton">
              <IonSkeletonText animated style={{ width: "60%" }} />
              <IonSkeletonText animated />
              <IonSkeletonText animated style={{ width: "88%" }} />
              <IonSkeletonText animated style={{ width: "70%" }} />
              <IonSkeletonText animated style={{ width: "60%" }} />
            </div>
            <IonList>
              <IonListHeader>
                <IonLabel>
                  <IonSkeletonText animated style={{ width: "20%" }} />
                </IonLabel>
              </IonListHeader>
              <IonItem>
                <IonAvatar slot="start">
                  <IonSkeletonText animated />
                </IonAvatar>
                <IonLabel>
                  <h3>
                    <IonSkeletonText animated style={{ width: "50%" }} />
                  </h3>
                  <p>
                    <IonSkeletonText animated style={{ width: "80%" }} />
                  </p>
                  <p>
                    <IonSkeletonText animated style={{ width: "60%" }} />
                  </p>
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated />
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText animated style={{ width: "50%" }} />
                  </h3>
                  <p>
                    <IonSkeletonText animated style={{ width: "80%" }} />
                  </p>
                  <p>
                    <IonSkeletonText animated style={{ width: "60%" }} />
                  </p>
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonSkeletonText
                  animated
                  style={{ width: "27px", height: "27px" }}
                  slot="start"
                />
                <IonLabel>
                  <h3>
                    <IonSkeletonText animated style={{ width: "50%" }} />
                  </h3>
                  <p>
                    <IonSkeletonText animated style={{ width: "80%" }} />
                  </p>
                  <p>
                    <IonSkeletonText animated style={{ width: "60%" }} />
                  </p>
                </IonLabel>
              </IonItem>
            </IonList>
          </>
        ) : (
          <div className="weekBilan">
            <div className="weekBilan_title">
              <IonText className="weekBilan_content_list_text_title">
                <h2>✅ Bilan de la semaine</h2>
              </IonText>
              <IonText>
                <p className="weekBilan_title_subtitle">
                  {/* {dataBilan.data[0].question} */}
                  {questions ? <>{questions.Data[0].libelle}</> : <></>}
                </p>
              </IonText>
            </div>
            <div className="weekBilan_content">
              <ul className="weekBilan_content_list">

                {answers ? (
                  <>
                    {answers.map((answer, index) => (
                      <li
                        key={index}
                        className="weekBilan_content_list_item "
                        id="toFast"
                        onClick={() => handleChangeSlide(answer.title)}
                      >
                        <div className="weekBilan_content_list_icon">
                          <span className="weekBilan_content_list_text_icon_one">
                            {/* Je n'arrive pas à afficher les emojis */}

                            {String.fromCodePoint(parseInt(answer.icon))}
                          </span>
                        </div>
                        <div className="weekBilan_content_list_text">
                          <IonText>
                            <h4 className="weekBilan_content_title_box">{answer.title}</h4>
                          </IonText>
                          <IonText>
                            <p className="weekBilan_content_text_box" >
                              {answer.description}
                            </p>
                          </IonText>
                        </div>
                      </li>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default WeekBilan;
