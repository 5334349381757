import {
    IonButton,
    IonContent,
    IonPage,
    IonText,
    IonHeader,
    IonBackButton,
    IonToolbar,
    IonButtons,
    IonLabel,
    IonCheckbox,
} from "@ionic/react";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router";
import FooterForm from "../../../Reusable/FooterForm";
import './newDispo.css'
import {isEmpty} from "../../../../Utils/Utils";

const NewGoalDisponibility = () => {
    const training = useSelector(state => state.training)
    const currentUser = useSelector(state => state.currentUser);
    const history = useHistory();
    const [disponibilityData, setDisponibilityData] = useState();
    const [practice_running_time_available_by_week, setPractice_running_time_available_by_week,] = useState();
    const [spin,setSpin]= useState(false)


    const handleChecked = (e) => {
        setDisponibilityData({
            ...disponibilityData,
            [e.target.name]: e.target.checked,
        });
    };


    useEffect(() => {
        axios
            .post(`${process.env.REACT_APP_STAGING_V2}/disponibility/foreach`, {
                debug: false, filter: [{
                    field: "userId", comparator: "==", operand: [[currentUser.id]],
                },], fields: [],
            }, {
                headers: {
                    Authorization: currentUser.token,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    setDisponibilityData(res.data.Data[0])
                    setPractice_running_time_available_by_week((parseInt(res.data.Data[0].numberOfRaces)))
                }
            })
            .catch((err) => {
            });
    }, [currentUser.id,currentUser.token])

    const handleCountUp = () => {
        setPractice_running_time_available_by_week(practice_running_time_available_by_week + 1);
        if (practice_running_time_available_by_week >= 7) {
            return setPractice_running_time_available_by_week(practice_running_time_available_by_week);
        }
    };
    const handleCountDown = () => {
        setPractice_running_time_available_by_week(practice_running_time_available_by_week - 1);
        if (practice_running_time_available_by_week <= 0) {
            return setPractice_running_time_available_by_week(practice_running_time_available_by_week);
        }
    };


    const handleSubmit = () => {
        setSpin(true)
        axios
            .post(`${process.env.REACT_APP_STAGING_V2}/disponibility/update`, {
                debug: false, data: {
                    numberOfRaces: practice_running_time_available_by_week.toString(),
                    id: disponibilityData.id,
                    userId: disponibilityData.userId,
                    monday: disponibilityData.monday,
                    tuesday: disponibilityData.tuesday,
                    wednesday: disponibilityData.wednesday,
                    thursday: disponibilityData.thursday,
                    friday: disponibilityData.friday,
                    saturday: disponibilityData.saturday,
                    sunday: disponibilityData.sunday,
                    week: parseInt(training[0].Plan.TemporalPlanId),
                },
            }, {
                headers: {
                    Authorization: currentUser.token,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    axios
                        .post(
                            `${process.env.REACT_APP_STAGING_V2}/plan/generate`,
                            {
                                debug: false,
                                data: {
                                    id: currentUser.id,
                                },
                            },{
                                headers: {
                                    Authorization: currentUser.token,
                                },
                            }
                        )
                        .then((res) => {

                            history.push('/tabs/tab1')
                            setSpin(false)

                        }).catch(err => {
                        console.log(err)
                    })

                }
            })
            .catch((err) => console.log(err));
    };


    return (<IonPage>
        <IonHeader mode="ios" class="ion-no-border">
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton
                        text=""
                        defaultHref="/newGoal"
                        className="headerBackButton"
                    />
                </IonButtons>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <>
                {!isEmpty(disponibilityData) && (<>
                    <div className="newDispo">
                        <div className="newDispo_content">
                            <IonText className="newDispo_text">
                                Combien de fois souhaite-tu courir par semaine ?
                            </IonText>
                            <div className="newDispo_globalButton">
                                <IonButton
                                    className="newDispo_button"
                                    onClick={handleCountDown}
                                >
                                    -
                                </IonButton>


                                <input value={practice_running_time_available_by_week}
                                       placeholder={practice_running_time_available_by_week}
                                       className="fabButton_weekOfRaces">

                                </input>
                                <IonButton
                                    className="newDispo_button"
                                    onClick={handleCountUp}
                                >
                                    +
                                </IonButton>
                            </div>

                        </div>
                        <div className="newDispo_content">
                            <IonText className="newDispo_text">
                                Quelles sont tes disponibilité ?
                            </IonText>
                            <div className="newDispo_disponibility_content">
                                <ul className="disponibility_list">
                                    <li className="disponibility_list_item">
                                        <IonLabel className="disponibility_list_label">L</IonLabel>
                                        <span
                                            className={disponibilityData.monday ? "disponibility_checkbox_box_checked" : "disponibility_checkbox_box"}
                                        >
                  <IonCheckbox
                      onIonChange={(e) => {
                          handleChecked(e);
                      }}
                      type="checkbox"
                      name="monday"
                      className="disponibility_list_item_checkbox"
                      defaultChecked={disponibilityData.monday}
                      checked={disponibilityData.monday}


                  />
                </span>
                                    </li>
                                    <li className="disponibility_list_item">
                                        <IonLabel className="disponibility_list_label">M</IonLabel>
                                        <span
                                            className={disponibilityData.tuesday ? "disponibility_checkbox_box_checked" : "disponibility_checkbox_box"}
                                        >
                  <IonCheckbox
                      onIonChange={(e) => {
                          handleChecked(e);
                      }}
                      type="checkbox"
                      name="tuesday"
                      className="disponibility_list_item_checkbox"
                      defaultValue={disponibilityData.tuesday}
                      checked={disponibilityData.tuesday}


                  />
                </span>
                                    </li>
                                    <li className="disponibility_list_item">
                                        <IonLabel className="disponibility_list_label">M</IonLabel>
                                        <span
                                            className={disponibilityData.wednesday ? "disponibility_checkbox_box_checked" : "disponibility_checkbox_box"}
                                        >
                  <IonCheckbox
                      onIonChange={(e) => {
                          handleChecked(e);
                      }}
                      type="checkbox"
                      name="wednesday"
                      className="disponibility_list_item_checkbox"
                      defaultValue={disponibilityData.wednesday}
                      checked={disponibilityData.wednesday}

                  />
                </span>
                                    </li>
                                    <li className="disponibility_list_item">
                                        <IonLabel className="disponibility_list_label">J</IonLabel>
                                        <span
                                            className={disponibilityData.thursday ? "disponibility_checkbox_box_checked" : "disponibility_checkbox_box"}
                                        >
                  <IonCheckbox
                      onIonChange={(e) => {
                          handleChecked(e);
                      }}
                      type="checkbox"
                      name="thursday"
                      className="disponibility_list_item_checkbox"
                      checked={disponibilityData.thursday}
                      defaultValue={disponibilityData.thursday}

                  />
                </span>
                                    </li>
                                    <li className="disponibility_list_item">
                                        <IonLabel className="disponibility_list_label">V</IonLabel>
                                        <span
                                            className={disponibilityData.friday ? "disponibility_checkbox_box_checked" : "disponibility_checkbox_box"}
                                        >
                  <IonCheckbox
                      onIonChange={(e) => {
                          handleChecked(e);
                      }}
                      type="checkbox"
                      name="friday"
                      className="disponibility_list_item_checkbox"
                      defaultChecked={disponibilityData.friday}
                      checked={disponibilityData.friday}

                  />
                </span>
                                    </li>
                                    <li className="disponibility_list_item">
                                        <IonLabel className="disponibility_list_label">S</IonLabel>
                                        <span
                                            className={disponibilityData.saturday ? "disponibility_checkbox_box_checked" : "disponibility_checkbox_box"}
                                        >
                  <IonCheckbox
                      onIonChange={(e) => {
                          handleChecked(e);
                      }}
                      type="checkbox"
                      name="saturday"
                      className="disponibility_list_item_checkbox"
                      defaultChecked={disponibilityData.saturday}
                      checked={disponibilityData.saturday}

                  />
                </span>
                                    </li>
                                    <li className="disponibility_list_item">
                                        <IonLabel className="disponibility_list_label">D</IonLabel>
                                        <span
                                            className={disponibilityData.sunday ? "disponibility_checkbox_box_checked" : "disponibility_checkbox_box"}
                                        >
                  <IonCheckbox
                      onIonChange={(e) => {
                          handleChecked(e);
                      }}
                      type="checkbox"
                      name="sunday"
                      className="disponibility_list_item_checkbox"
                      defaultValue={disponibilityData.sunday}
                      checked={disponibilityData.sunday}
                  />
                </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <FooterForm spin={spin} onClick={handleSubmit}/>
                </>)}
            </>

        </IonContent>
    </IonPage>);
};

export default NewGoalDisponibility;
  