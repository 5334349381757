import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {store} from "../../../redux";
import {forEachTraining} from "../../../../API/Training/GetTraining";
import {useHistory} from "react-router";
import {ChangeLogin} from "../../currentUser/currentUserSlice";


export const productsApi = createApi({
    reducerPath: "productsApi",


    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_STAGING_V2}`, prepareHeaders: (headers, {getState}) => {
            headers.set('authorization', store.getState().currentUser.token)
            return headers
        }
    }),
    endpoints: (builder) => ({
        getAllProducts: builder.query({
            query: () => ({
                url: "/trainingweekplan/foreach", method: "POST",
                body: {
                    debug: false,
                    filter: [
                        {
                            field: "userId",
                            comparator: "==",
                            operand: [[store.getState().currentUser.id]],
                        },
                    ],
                    fields: [],

                },
                validateStatus: (response, result) => (

                    response.status === 200 && (!result.isError, store.dispatch(ChangeLogin({
                        isLoggin: true,
                        isRegister: false
                    }))))


            }),
        }),


    }),
});

export const {useGetAllProductsQuery, useUpdateTrainingMutation} = productsApi;


