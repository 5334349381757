import React, {useEffect} from "react"
import {EmailComposer} from "@awesome-cordova-plugins/email-composer";
import {useSelector} from "react-redux";
import {IonContent, IonHeader, IonPage, IonToolbar} from "@ionic/react";
import logoBlanc from "../../../../assets/pictoFlit/LOGO_FLIT_blanc2.png";
import chaussure from "../../../../assets/menu/chaussure_bleu.png";
import {isEmpty} from "../../../../Utils/Utils";
import moment from "moment";
import './errorWeekPlan.css'
import {useGetAllProductsQuery} from "../../../../redux/slices/ApiRedux/Vip/VipSlice";


const ErrorWeekPlan = () => {

    const currentUser = useSelector(state => state.currentUser)
    const goal = useSelector(state => state.goal)


    let email = {
        to: `support@flit-sport.fr`,
        subject: `[Erreur Flit Run] Erreur dans mon plan d\'entraînement - utilisateur ${currentUser.email})`,
        body: `Bonjour,Je rencontre un bug dans mon programme Flit Sport ${currentUser.lastName}`,
        isHtml: true
    }



    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="tab1_header">
                    <div className="tab1_content">
                        <img className="tab1_logo" src={logoBlanc} alt="logo"/>
                        <div className={"tab1_progressBar_objectiv"}>
                            <div className={"tab1_progressBar_objectiv_icon_box"}>
                                <img className={"tab1_progressBar_objectiv_icon"} src={chaussure}
                                     alt={"logo_chaussure"}/>
                                <div>{!isEmpty(goal[0]) && (
                                    <>
                                        {
                                            goal[0].filter(f => f.main === true).map((f, index) => (
                                                <div key={index}>
                                                    {
                                                        index === 0 && (
                                                            <p>   {
                                                                f.name === "Semi-marathon" ? "21k" : f.name === "Marathon" ? "42k" : f.name === "Pas d'objectif" ? "?" : f.name
                                                            }
                                                            </p>
                                                        )
                                                    }
                                                </div>

                                            ))
                                        }


                                    </>
                                )}</div>
                            </div>

                            {
                                !isEmpty(goal[0]) && (
                                    <>
                                        {
                                            goal[0].filter(f => f.main === true).map((f, index) => (
                                                <div key={index}>
                                                    {
                                                        index === 0 && (
                                                            <p>{moment(f.date).format(('l'))}</p>
                                                        )
                                                    }
                                                </div>
                                            ))
                                        }
                                    </>
                                )
                            }

                            {/*     <p>{moment(goal.filter(f => f.main === true)[0].date).format(('l'))}</p>*/}
                        </div>
                    </div>


                </IonToolbar>
            </IonHeader>

            <IonContent>
                <div>
                    <div className="errorWeek_box_title">
                        <h3>Une erreur est survenue</h3>
                        <h3>Contacter le support</h3>
                        <h3><a
                            onClick={() => {
                                EmailComposer.open(email)
                            }}><b>support@flit-sport.fr</b></a></h3>

                    </div>


                </div>
            </IonContent>
        </IonPage>

    )
}


export default ErrorWeekPlan