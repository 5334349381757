import React, {useEffect, useState} from 'react';
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage, IonRouterLink,
    IonText,
    IonToolbar,
} from "@ionic/react";
import "./noviceLevel.css"
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "../../../../../Utils/Utils";
import {getNovice} from "../../../../../redux/slices/currentUser/currentUserSlice";
import {useHistory} from "react-router";
import logo from "../../../../../assets/FormulaireAssets/3.png"
import FooterForm from "../../../../Reusable/FooterForm";

const NoviceLevel = () => {

    const [data, setData] = useState()
    const user = useSelector((state) => state.currentUser);
    const dispatch = useDispatch()
    const history = useHistory()
    const [idObj, setIdObj] = useState()
    const [active, setActive] = useState({
        first: false,
        second: false,
        third: false
    })


    useEffect(() => {
        axios
            .post(
                `${process.env.REACT_APP_STAGING_V2}/novicetype/forEach`,
                {
                    debug: false,
                    filter: [],
                    fields: [],
                },
                {
                    headers: {
                        Authorization: user.token,
                    },
                }
            )
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => console.error(err));
    }, [user.token])



    const handleClick = () => {

        if (isEmpty(idObj)) {
            return null

        } else {
            if (active.third === true) {
                dispatch(getNovice({typeId: idObj}))
                history.push("/noviceLevel/question1")
            } else {
                axios
                    .post(
                        `${process.env.REACT_APP_STAGING_V2}/noviceinfo/create`,

                        {
                            debug: false,
                            data: {
                                userId: user.id,
                                typeId: idObj,
                            },
                            args: {},
                        },
                        {
                            headers: {
                                Authorization: user.token,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.status === 200) {
                            history.push("/disponibilityWeek")
                        }

                    })
                    .catch((err) => console.error(err));
            }
        }

    }

    return (
        <IonPage>

            <IonContent className={"objectif content_form"}>
                <div className="noviceLevel">

                    <img src={logo} alt={"logo"}/>
                   <h1 className={" noviceLevel_title title_form_fredoka"}> Ton <span
                        style={{color: "var(--lightGreenFlit)"}}>profil</span></h1>
                    <div className="noviceLevel_textContent">
                        <IonText>
                            <p className="noviceLevel_subtitle"> Tu n'as pas encore d'<b
                                style={{color: "var(--lightGreenFlit)"}}>historique de données</b> sur cette appli. Pour démarrer ton plan, <b style={{color: "var(--lightGreenFlit)"}}>sélectionne
                                    le profil</b> qui te correspond le plus.</p>
                        </IonText>

                    </div>

                    <div className="noviceLevel_content">
                        {
                            !isEmpty(data) && (
                                <ul>

                                    <li className={active.first ? "noviceLevel_content_list_item novice_active" : "noviceLevel_content_list_item"}
                                        onClick={() => {
                                            setIdObj(data.Data.filter(f => f.code ==="beginner").map(f => f.id).toString());
                                            setActive({
                                                first: true,
                                                second: false,
                                                third: false
                                            })
                                        }}>

                                        <div>
                                            <p className={active.first ? "noviceLevel_content_list_item_title novice_active" : "noviceLevel_content_list_item_title"}>👶
                                                Je suis débutant.e</p>
                                            <p className={active.first ? "noviceLevel_content_list_item_text novice_active" : "noviceLevel_content_list_item_text"}>Je
                                                n’ai jamais vraiment
                                                couru et je n’ai pas l’habitude de faire du sport. Je ne pense pas
                                                pouvoir courir 30 minutes sans interruption.</p>
                                        </div>
                                    </li>
                                    <li className={active.second ? "noviceLevel_content_list_item novice_active" : "noviceLevel_content_list_item"}
                                        onClick={() => {
                                            setIdObj(data.Data.filter(f => f.code ==="former_runner").map(f => f.id).toString());
                                            setActive({
                                                first: false,
                                                second: true,
                                                third: false
                                            })
                                        }}>
                                        <div>
                                            <p className={active.second ? "noviceLevel_content_list_item_title novice_active" : "noviceLevel_content_list_item_title"}>🤸 Je suis sportif</p>
                                            <p className={active.second ? "noviceLevel_content_list_item_text novice_active" : "noviceLevel_content_list_item_text"}>J’ai
                                                déjà couru et j’ai l’habitude du sport. Je peux courir 30min sans
                                                interruption et faire des accélérations.</p>
                                        </div>

                                    </li>
                                    <li className={active.third ? "noviceLevel_content_list_item novice_active" : "noviceLevel_content_list_item"}
                                        onClick={() => {
                                            setIdObj(data.Data.filter(f => f.code ==="regular_runner").map(f => f.id).toString());
                                            setActive({
                                                first: false,
                                                second: false,
                                                third: true
                                            })
                                        }}>

                                        <div>
                                            <p className={active.third ? "noviceLevel_content_list_item_title novice_active" : "noviceLevel_content_list_item_title"}>🏃
                                                Je cours déjà en ce
                                                moment</p>
                                            <p className={active.third ? "noviceLevel_content_list_item_text novice_active" : "noviceLevel_content_list_item_text"}>Je
                                                n’ai pas de données sur
                                                Strava mais j’ai déjà l’habitude de courir régulièrement.</p>
                                        </div>
                                    </li>

                                </ul>
                            )
                        }
                    </div>

                    <FooterForm text={"Suivant"} onClick={handleClick} />
                </div>


            </IonContent>
        </IonPage>
    );
};

export default NoviceLevel;