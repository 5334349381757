import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonContent,
    IonImg,
    IonText,
    IonButton,
    IonModal,
    IonDatetime,
    IonAlert,
    IonIcon,
    isPlatform,
    IonBackButton, IonLoading,
} from "@ionic/react";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useHistory, useParams} from "react-router";
import logo from "../../../../assets/FormulaireAssets/10.png";
import * as IoIcons from "react-icons/io5";
import moment from "moment";
import SkeletonObjectif from "./SkeletonObjectif/SkeletonObjectif";
import "./objectif.css";
import FooterForm from "../../../Reusable/FooterForm";
import {calendar, chevronDown} from "ionicons/icons";
import {Capacitor} from "@capacitor/core";
import {emptyGoals, getGoal} from "../../../../redux/slices/goals/goalSlice";
import {forEachGoal} from "../../../../API/Goal/GetGoal";
import {forEachTraining} from "../../../../API/Training/GetTraining";
import {useGetAllProductsQuery} from "../../../../redux/slices/ApiRedux/Vip/VipSlice";
import {isEmpty} from "../../../../Utils/Utils";

const Objectif = () => {
    const [question, setQuestions] = useState();
    const [showModal, setShowModal] = useState(false)
    const user = useSelector((state) => state.currentUser);
    const history = useHistory();
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
    const isModify = history.location.pathname
    const goal = useSelector(state => state.goal);
    const dispatch = useDispatch();
    const [showLoading, setShowLoading] = useState(false)


    const [showAlert, setShowAlert] = useState(false);

    const [objectif, setobjectif] = useState({
        name: "", id: "",
    });

    // get the goal type { 5, 10K ect..} for the list and their Id
    useEffect(() => {
        axios
            .post(`${process.env.REACT_APP_STAGING_V2}/goalType/find`, {
                debug: false, filter: [], fields: [],
            }, {
                headers: {
                    Authorization: user.token,
                },
            })
            .then((res) => {

                setQuestions(res.data);
            })
            .catch((err) => console.error(err));
    }, [user.token]);

    const handleobjectif = (e) => {
        const {name, value} = e.target;
        setobjectif({...objectif, name: name, id: value});

    };
    const handleChooseDate = (e) => {
        const {value} = e.target;

        if (objectif.name === "Progresser sans objectif") {
            setDate(null);
        } else {
            const dataDate = value;
            setDate(moment(dataDate).format("YYYY-MM-DD"));


        }
    };

    // function for add the objectif only valaible on register form

    const handleSubmit = () => {

        if (date === undefined || objectif.id === "" || objectif.name === "") {
            setShowAlert(true)
        } else {

            axios
                .post(`${process.env.REACT_APP_STAGING_V2}/goals/create`, {
                    debug: false, data: {
                        userId: user.id, main: true, goalType: objectif.id, date: date.concat(" 00:00:00 +0000 UTC"),
                    },
                }, {
                    headers: {
                        Authorization: user.token,
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        history.push("/objectifInter");
                    }
                    if (res.status === 400) {
                        setShowAlert(true);
                    }
                })
                .catch((err) => console.error(err));
        }
    };


    // function for modify the objectif only valaible on modify main Objectiv
    const handleModify = () => {
        setShowLoading(true)

        const goalMain = goal[0].filter(t => t.main === true)


        if (date === undefined) {
            setShowAlert(true)
        } else {
            axios
                .post(`${process.env.REACT_APP_STAGING_V2}/goals/update`, {
                    debug: false, data: {
                        id: goalMain[0].id, goalType: objectif.id, main: true, date: date.concat(" 00:00:00 +0000 UTC"),
                    },
                }, {
                    headers: {
                        Authorization: user.token,
                    },
                })
                .then((res) => {
                    if (res.status === 200) {

                        axios
                            .post(`${process.env.REACT_APP_STAGING_V2}/plan/generate`, {
                                debug: false, data: {
                                    id: user.id,
                                },
                            }, {
                                headers: {
                                    Authorization: user.token,
                                },
                            }).then(res => {
                            if (res.status == 200) {
                                dispatch(emptyGoals())
                                dispatch(forEachGoal(user))
                                setTimeout(() => {
                                    setShowLoading(false)
                                    history.push("/tab4")

                                }, 500)
                            }
                        }).catch(e => setShowAlert(true)
                        )


                    }
                    if (res.status === 400) {
                        setShowLoading(false)
                        setShowAlert(true);
                    }
                })
                .catch((err) => console.error(err));
        }
    }

    return (<IonPage>
        {isModify === "/modifyMainObj" ? <IonHeader class="ion-no-border">
            <IonToolbar className="modify_header" mode="ios">
                <IonButtons className="day_buttons_header" slot="start">
                    <IonBackButton
                        defaultHref="/tab4"
                        text=""
                        className="headerBackButton"
                    />
                </IonButtons>
            </IonToolbar>
        </IonHeader> : ""}

        <IonContent className={"objectif content_form"}>
            {isEmpty(question) ? (<SkeletonObjectif/>) : (<div className=" div_principal">
                {isModify === "/modifyMainObj" ? "" : <IonImg src={logo} className="objectif_logo "/>}

                <div className={"objectif_title_box"}>
                    <h1 className={"objectif_title_box_part1 title_form_fredoka"}>Mon objectif</h1>
                    <h1 className={"objectif_title_box_part2 title_form_fredoka"}>principal</h1>
                </div>
                <p className={'objectif_subtitle'}>Choisis ton <b
                    style={{color: 'var(--lightGreenFlit)'}}>objectif</b> et des entraînements seront adaptés en
                    fonction.</p>
                <ul className="objectif_list">
                    {question.Data.map((q) => (<li key={q.id} className="objectif_list_item">
                        <button
                            onClick={(e) => handleobjectif(e)}
                            value={q.id}
                            name={q.name}
                            className={objectif.name === q.name ? "objectif_list_item_button type_active" : "objectif_list_item_button"}
                        >
                            {q.name}
                        </button>
                    </li>))}
                </ul>

                <div className="objectif_time">
                    <ul className="objectif_time_list">
                        <li className="objectif_time_list_item">
                            <div className={"objectif_icon_button_box"}>
                                <IonIcon className={"objectif_icon_button"} icon={calendar}></IonIcon>
                            </div>
                            <IonButton
                                id="open-modal"
                                onClick={() => setShowModal(true)}
                                className="objectif_modal_button"
                                disabled={objectif.name === "Pas d'objectif"}
                            >
                                {date === null ? (<div className="objectif_time_date_text">
                                    <IoIcons.IoCalendarNumber/>
                                    <p>Date</p>
                                </div>) : (<>
                                    <p>{moment(date).format("ll", "fr-FR")}</p>
                                </>)}
                            </IonButton>
                            <IonModal mode={isPlatform("ios") ? "ios" : "md"} className="objectif_modal"
                                      isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
                                <IonDatetime
                                    mode={Capacitor.getPlatform() === "ios" ? "ios" : "md"}
                                    max="2061-12-12"
                                    min={moment().format("YYYY-MM-DD")}
                                    defaultValue={date}
                                    presentation="date"
                                    name="date"
                                    onIonChange={handleChooseDate}
                                ></IonDatetime>
                            </IonModal>
                        </li>
                    </ul>
                    <p className={"objectif_subtitle"}>💡 On ne te demande pas ton chrono visé, on t’emmène au
                        maximum de tes capacités.</p>
                </div>
                <IonAlert
                    mode={Capacitor.getPlatform() === 'ios' ? "ios" : "md"}
                    isOpen={showAlert}
                    onDidDismiss={() => {
                        setShowAlert(false);
                    }}
                    header="Oops !"
                    subHeader="Une erreur est survenue"
                    message="Tu dois saisir tous les champs"
                    buttons={["OK"]}
                />

                <FooterForm text={isModify === "/modifyMainObj" ? "Modifier" : "Suivant"}
                            onClick={isModify === "/modifyMainObj" ? handleModify : handleSubmit}/>
            </div>)}
            <IonLoading
                cssClass='my-custom-class'
                mode={Capacitor.getPlatform() === "ios" ? "ios" : "md"}
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Chargement...'}
            />
        </IonContent>
    </IonPage>);
};

export default Objectif;
