import {
    IonIcon,
    IonFab,
    IonFabButton,
    IonNav,
    IonModal,
    IonContent,
    IonHeader,
    IonButtons,
    IonToolbar,
    IonButton,
    IonRefresher,
    IonRefresherContent,
} from "@ionic/react";
import * as BsIcons from "react-icons/bs";
import {useEffect, useState} from "react";
import "./trainingDayPlan.css";
import {isEmpty} from "../../../../../Utils/Utils";
import NoFractionne from "./NoFractionne/NoFractionne";
import {informationCircleOutline, add, stopwatch, stopwatchOutline} from "ionicons/icons";
import VmaLength from "./VMA/VmaLength";
import trente from "../../../../../assets/iconSeance/3030.png";
import seuil from "../../../../../assets/iconSeance/seuil.png";
import entrainementNP from "../../../../../assets/iconSeance/entrainement_non_planifie.png";
import enduranceFondamental from "../../../../../assets/iconSeance/endurance_fondamentale.png";
import cote from "../../../../../assets/iconSeance/cote.png";
import VMAIcon from "../../../../../assets/iconSeance/VMA.png";
import tempo from "../../../../../assets/iconSeance/tempo.png";
import sortieLongue from "../../../../../assets/iconSeance/sortie_longue.png";
import fractionneBI from "../../../../../assets/iconSeance/fractionne_basse_intensit‚.png";
import fractionneLong from "../../../../../assets/iconSeance/fractionne_long.png";
import allureSoutenue from "../../../../../assets/iconSeance/footing_allure_soutenue.png";
import chaussure from "../../../../../assets/menu/semaine.png";
import progressif from "../../../../../assets/iconSeance/progressif.png";
import fractionneCourt from "../../../../../assets/iconSeance/fractionne_court.png";
import ligneDroite from "../../../../../assets/iconSeance/lignes droites.png";
import {Link} from 'react-router-dom'
import Minuteur from "./Minuteur/Minuteur";
import {useGetAllProductsQuery} from "../../../../../redux/slices/ApiRedux/Vip/VipSlice";

const TrainingDayPlan = ({currentDay, day, repeat, count}) => {

    const [isOpen, setIsOpen] = useState(false)

    const {refetch} = useGetAllProductsQuery();




    const iconSeance = (data) => {

        if (data === "30/30") {
            return trente
        }
        if (data === "Seuil") {
            return seuil
        }
        if (data === "Entraînement non planifié" || data === "Non planifié") {
            return entrainementNP
        }
        if (data === "Endurance fondamentale") {
            return enduranceFondamental
        }
        if (data === "Côtes") {
            return cote
        }
        if (data === "VMA") {
            return VMAIcon
        }
        if (data === "Tempo") {
            return tempo
        }
        if (data === "Sortie longue") {
            return sortieLongue
        }
        if (data === "Basse intensité") {
            return fractionneBI
        }
        if (data === "Fractionné long") {
            return fractionneLong
        }
        if (data === "Allure soutenue") {
            return allureSoutenue
        }
        if (data === "Progressif") {
            return progressif
        }
        if (data === "Fractionné court") {
            return fractionneCourt
        }
        if (data === "Lignes droites") {
            return ligneDroite
        } else {
            return chaussure
        }
    }


    return (
        <IonContent className="app_content" class="scroll-content">
            <>
                {currentDay.map((c, index) => (
                    <>
                        <div key={index} className="trainingDay">
                            <div className="trainingDay_icon_content">

                                <div className="trainingDay_title_content">
                                    <div className="trainingDayPlanBox_session_content_boxIcon">
                                        <img alt={"icon seance"}
                                             src={iconSeance(currentDay[count].session_information.title.split(" - ")[0].trim())}
                                             className="trainingDayPlanBox_session_content_icon"/>
                                    </div>
                                    {
                                        currentDay[count].session_information.title.split(" - ")[1] === undefined ?
                                            <h4 className="trainingDay_typeof">{currentDay[count].session_information.title}</h4> :
                                            <h4 className="trainingDay_typeof">{currentDay[count].session_information.title.split(" - ")[0]}</h4>
                                    }
                                    {
                                        currentDay[count].session_information.title.split(" - ")[1] === undefined ?
                                            "" :
                                            <>
                                                {
                                                    currentDay[count].session_information.title.split("-")[0].trim() === "30/30" && (
                                                        <Link

                                                            to={`/pedagogieDetails/30-30`}
                                                            className="trainingWeekPlan_link"
                                                        >
                                                            <IonIcon className="trainingDay_info_icon"
                                                                     icon={informationCircleOutline}></IonIcon>
                                                        </Link>
                                                    )
                                                }
                                                {
                                                    currentDay[count].session_information.title.split("-")[0].trim() === "Marche / Course" && (
                                                        <Link

                                                            to={`/pedagogieDetails/Marche-Course`}
                                                            className="trainingWeekPlan_link"
                                                        >
                                                            <IonIcon className="trainingDay_info_icon"
                                                                     icon={informationCircleOutline}></IonIcon>
                                                        </Link>
                                                    )
                                                }
                                                {
                                                    currentDay[count].session_information.title.split("-")[0].trim() !== "Marche / Course" && currentDay[count].session_information.title.split("-")[0].trim() !== "30/30" && (
                                                        <Link

                                                            to={`/pedagogieDetails/${currentDay[count].session_information.title.split(" - ")[0]}`}
                                                            className="trainingWeekPlan_link"
                                                        >
                                                            <IonIcon className="trainingDay_info_icon"
                                                                     icon={informationCircleOutline}></IonIcon>
                                                        </Link>
                                                    )
                                                }


                                            </>


                                    }


                                </div>
                            </div>
                            {
                                currentDay[count].session_information.title === "Entraînement non planifié" || currentDay[count].session_information.title === "Non planifié" ?

                                    <div className="trainingDay_totalTime_content">
                                        <BsIcons.BsStopwatch className="trainingDay_totalTime_icon"/>

                                        <h5 className="trainingDay_totalTimeNo">
                                            {
                                                currentDay[count].session_information.session_basic_data.total_time.split(":").length === 2 ? currentDay[count].session_information.session_basic_data.total_time.split(":")[count] + "min" : currentDay[count].session_information.session_basic_data.total_time.split(":")[count] === "count" ? "" : +currentDay[count].session_information.session_basic_data.total_time.split(":")[count] + "h" + currentDay[count].session_information.session_basic_data.total_time.split(":")[1] + "min"
                                            }
                                        </h5>
                                    </div>
                                    :
                                    <>
                                        {!isEmpty(currentDay[count].session_information.title.split("-")[1]) && (
                                            <div className="trainingDay_totalTime_content">
                                                <BsIcons.BsStopwatch className="trainingDay_totalTime_icon"/>
                                                {
                                                    currentDay[count].session_information.title.split(" - ")[1] === undefined || currentDay[count].session_information.title.split(" - ")[1] === "?" ?
                                                        <h5 className="trainingDay_goalsTotalTime">?</h5> :
                                                        <h5 className="trainingDay_goalsTotalTime">{currentDay[count].session_information.title.split("-")[1].split(":").length === 2 ? currentDay[count].session_information.title.split("-")[1].split(":")[0] + "min" : currentDay[count].session_information.title.split("-")[1].split(":")[count] === "count" ? "" : +currentDay[count].session_information.title.split("-")[1].split(":")[0] + "h" + currentDay[count].session_information.title.split("-")[1].split(":")[1]}</h5>
                                                }
                                                <h5 className="trainingDay_totalTime">
                                                    {
                                                        currentDay[count].session_information.session_basic_data && (
                                                            currentDay[count].session_information.session_basic_data.total_time.split(":").length === 2 ? "(" + currentDay[count].session_information.session_basic_data.total_time.split(":")[count] + "min" + ')' : "(" + currentDay[count].session_information.session_basic_data.total_time.split(":")[count] === "count" ? "" : "(" + currentDay[count].session_information.session_basic_data.total_time.split(":")[count] + "h" + currentDay[count].session_information.session_basic_data.total_time.split(":")[1] + ")"
                                                        )}
                                                </h5>
                                            </div>
                                        )}
                                    </>
                            }
                            <div>
                                {

                                    currentDay[count].session_information.session_basic_data && (

                                        <ul className="trainingDay_data_value_list">
                                            <li className="trainingDay_data_value_list_item">
                                                <div className="trainingDay_data_value_list_box">
                                                    <p className="trainingDay_data_value_list_box_title">Distance</p>
                                                    <p className="trainingDay_data_value_list_box_data">{currentDay[count].session_information.session_basic_data.total_distance}</p>

                                                </div>
                                            </li>
                                            <li className="trainingDay_data_value_list_item">
                                                <div className="trainingDay_data_value_list_box">
                                                    <p className="trainingDay_data_value_list_box_title">Allure</p>
                                                    <p className="trainingDay_data_value_list_box_data">{currentDay[count].session_information.session_basic_data.avg_pace}</p>

                                                </div>
                                            </li>
                                            <li className="trainingDay_data_value_list_item">
                                                <div className="trainingDay_data_value_list_box_right">
                                                    <p className="trainingDay_data_value_list_box_title">Dénivelé +</p>
                                                    <p className="trainingDay_data_value_list_box_data">{currentDay[count].session_information.session_basic_data.elevation}</p>

                                                </div>
                                            </li>
                                        </ul>

                                    )

                                }

                            </div>

                            <div className="trainingDay_button"></div>
                            {
                                currentDay[count].session_information.title === "Non planifié" || currentDay[count].session_information.title === "Entraînement non planifié" ?
                                    <NoFractionne c={c}/> : <>
                                        {
                                            currentDay[count].session_information.session[1].steps.map(m => m.repeat).filter(f => f >= 2).length >= 1 && currentDay[count].session_information.session[1].steps.length > 1 ?
                                                <VmaLength c={c} repeat={repeat}/>
                                                :
                                                <>
                                                    {
                                                        currentDay[count].session_information.session[1].steps.map(m => m.repeat).filter(f => f >= 2).length >= 1 && (
                                                            <VmaLength c={c} repeat={repeat}/>
                                                        )
                                                    }
                                                    {
                                                        currentDay[count].session_information.session[1].steps.map(m => m.repeat).filter(f => f >= 2).length === 0 && (
                                                            <NoFractionne c={c} repeat={repeat}/>
                                                        )
                                                    }
                                                </>
                                        }


                                    </>
                            }


                        </div>

                        {/*{*/}
                        {/*    isEmpty(c.session_information.session[0].steps[0].done)&&(*/}
                        {/*        <IonFab vertical="bottom"  horizontal="end" slot="fixed">*/}
                        {/*            <IonFabButton className={"trainingDay_fabButton"}>*/}
                        {/*                <IonIcon style={{width: "70%", height: "70%"}} onClick={() => setIsOpen(true)}*/}
                        {/*                         icon={stopwatchOutline}></IonIcon>*/}
                        {/*                <Minuteur showModal={isOpen} setShowModal={setIsOpen} globalData={c}/>*/}
                        {/*            </IonFabButton>*/}
                        {/*        </IonFab>*/}
                        {/*    )*/}
                        {/*}*/}

                    </>
                ))}
            </>

        </IonContent>
    );
};

export default TrainingDayPlan;
