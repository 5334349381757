import React, {useEffect, useState} from 'react';
import * as FaIcons from "react-icons/fa";
import './pedagogie.css'
import { IonSpinner, IonText} from "@ionic/react";
import {useHistory} from "react-router";
import {Link} from"react-router-dom"
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "../../../../Utils/Utils";
import {getFromPedagogie} from "../../../../redux/slices/pedagogie/pedagogie.slice";
import SkeletonPedaList from "./SkeletonPedaList/SkeletonPedaList";
import trente from "../../../../assets/iconSeance/3030.png";
import seuil from "../../../../assets/iconSeance/seuil.png";
import entrainementNP from "../../../../assets/iconSeance/entrainement_non_planifie.png";
import enduranceFondamental from "../../../../assets/iconSeance/endurance_fondamentale.png";
import cote from "../../../../assets/iconSeance/cote.png";
import VMAIcon from "../../../../assets/iconSeance/VMA.png";
import tempo from "../../../../assets/iconSeance/tempo.png";
import sortieLongue from "../../../../assets/iconSeance/sortie_longue.png";
import fractionneBI from "../../../../assets/iconSeance/fractionne_basse_intensit‚.png";
import fractionneLong from "../../../../assets/iconSeance/fractionne_long.png";
import allureSoutenue from "../../../../assets/iconSeance/footing_allure_soutenue.png";
import chaussure from "../../../../assets/menu/semaine.png";
import progressif from '../../../../assets/iconSeance/progressif.png'
import fractionneCourt from "../../../../assets/iconSeance/fractionne_court.png";
import ligneDroite from "../../../../assets/iconSeance/lignes droites.png"
import logoPeda from "../../../../assets/pictoFlit/Ampoules icone conseil.png"
import monte from "../../../../assets/iconSeance/montee en charge.png"
import recup from "../../../../assets/iconSeance/recuperation.png"

const Pedagogie = ({data}) => {

    const [show, setShow] = useState(false)
    const history = useHistory()
    const pedagogie = useSelector(state => state.pedagogie)
    const dispatch = useDispatch()
    const iconSeance = (data) => {

        if (data === "30/30") {
            return trente
        }
        if (data === "Seuil") {
            return seuil
        }
        if (data === "Entraînement non planifié" || data === "Non planifié") {
            return entrainementNP
        }
        if (data === "Endurance fondamentale") {
            return enduranceFondamental
        }
        if (data === "Côtes") {
            return cote
        }
        if (data === "VMA") {
            return VMAIcon
        }
        if (data === "Tempo") {
            return tempo
        }
        if (data === "Sortie longue") {
            return sortieLongue
        }
        if (data === "Basse intensité") {
            return fractionneBI
        }
        if (data === "Fractionné long") {
            return fractionneLong
        }
        if (data === "Allure soutenue") {
            return allureSoutenue
        }
        if (data === "Progressif") {
            return progressif
        }
        if (data === "Fractionné court") {
            return fractionneCourt
        }
        if (data === "Lignes droites") {
            return ligneDroite
        }
        if(data === "Montée en charge") {
            return monte
        }
        if(data === "Récupération"){
            return recup
        }
        else {
            return chaussure
        }
    }


    return (
        <div className="pedagogie">

            <div className="pedagogie_title_content">



                   <img src={logoPeda} alt={"logo pedagogie"} className="pedagogie_img"/>
                <div className="pedagogie_title_box">
                    <div className="pedagogie_title_box_second">
                        <h2 className={"pedagogie_title_conseil"}>Les Conseils</h2>
                        <h2>Flit Run</h2>
                    </div>
                </div>
            </div>
            {
                isEmpty(data) && (
                    <SkeletonPedaList/>
                )
            }

            {!isEmpty(data) && (

                <div className="pedagogie_seance">
                    <h3 className="pedagogie_seance_title_training">Phases d'entraînement</h3>

                    <ul className="pedagogie_seance_list">
                        {data.filter(peda => peda.idTag === "sde").map((d, index) => {
                            return (
                                <li className="pedagogie_seance_list_item" key={index}><Link style={{width:"100%"}}
                                    to={`/pedagogieDetails/${d.info.title}`}>
                                    <div className="pedagogie_content_icon">
                                        <img className='pedagogie_icon_training' src={iconSeance(d.info.title)} alt={d.info.title}/>
                                        <IonText><p className="pedagogie_text_training">{d.info.title}</p></IonText>
                                    </div>

                                </Link></li>
                            )
                        })}
                    </ul>

                    <h3 className="pedagogie_seance_title"> Types de séances</h3>


                    <ul className="pedagogie_seance_list">
                        {data.filter(peda => peda.idTag === "aaa").map((d, index) => {

                            if (d.info.title === "30/30") {
                                return (
                                    <li className="pedagogie_seance_list_item" key={index}><Link style={{width:"100%"}}
                                        to={`/pedagogieDetails/30-30`}>
                                    <div className="pedagogie_content_icon">
                                        <img className='pedagogie_icon' src={iconSeance(d.info.title)} alt={d.info.title}/>
                                        <IonText><p>{d.info.title}</p></IonText>

                                    </div>
                                    </Link></li>
                                )
                            }
                            if (d.info.title === "Marche/Course") {
                                return (
                                    <li className="pedagogie_seance_list_item" key={index}><Link style={{width:"100%"}}
                                                                                                 to={`/pedagogieDetails/Marche-Course`}>
                                        <div className="pedagogie_content_icon">
                                            <img className='pedagogie_icon' src={iconSeance(d.info.title)} alt={d.info.title}/>
                                            <IonText><p>{d.info.title}</p></IonText>

                                        </div>
                                    </Link></li>
                                )
                            }
                            else {
                                return (
                                    <li className="pedagogie_seance_list_item" key={index}><Link style={{width:"100%"}}
                                        to={`/pedagogieDetails/${d.info.title}`}>
                                        <div className="pedagogie_content_icon">
                                        <img className='pedagogie_icon' src={iconSeance(d.info.title)} alt={d.info.title}/>
                                        <IonText><p>{d.info.title}</p></IonText>
                                        </div>

                                    </Link></li>
                                )

                            }


                        })}
                    </ul>


                </div>
            )
            }
        </div>
    );
};

export default Pedagogie;
