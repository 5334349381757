import React from 'react';
import {IonText} from "@ionic/react";
import {isEmpty} from "../../../../../../Utils/Utils";
import "./noFractionne.css"
import logoIntensity from "../../../../../../assets/pictoFlit/intensite.png";
import logoRecovery from "../../../../../../assets/pictoFlit/recuperation.png";

const NoFractionne = ({c}) => {


    return (
        <div>
            <ul className="trainingDay_globalList">
                <li>
                    {
                        c.session_information.title === "Entraînement non planifié" ? "" :
                            <div className="span_test"></div>
                    }

                </li>
                <div className="trainingDay_content_box">
                    {c.session_information.session.map((x, index) => (
                        <li key={x.label}>
                            <div className="trainingDay_data_box">
                                <div
                                    className={
                                        x.label === "Session"
                                            ? "trainingDay_data_box_duration"
                                            : "trainingDay_data_box_duration_white"
                                    }
                                >
                                    <div className="trainingDay_boxTitle">
                                        <div className="trainingDay_label_point_training"></div>

                                        <IonText>
                                            <p className="trainingDay_data_box_duration_title">
                                                {x.label}
                                            </p>
                                        </IonText>
                                        <>
                                            {
                                                x.label === "Echauffement" && (
                                                    <IonText>
                                                        <p className="trainingDay_data_box_duration_allure">
                                                            Allure (min/km)
                                                        </p>
                                                    </IonText>
                                                )
                                            }
                                            {
                                                c.session_information.title === "Entraînement non planifié" ?
                                                    <IonText>
                                                        <p className="trainingDay_data_box_duration_allure">
                                                            Allure (min/km)
                                                        </p>
                                                    </IonText> : ""
                                            }
                                        </>
                                    </div>
                                    <div className="trainingDay_box">
                                        <ul className="trainingDay_data_box_duration_list">
                                            {x.steps.map((step, index) => (
                                                <>
                                                    <li
                                                        key={step.repeat}
                                                        className="trainingDay_data_box_duration_list_item"
                                                    >
                                                        <div className="noFractionné_box">
                                                            <div className="box-test">
                                                                <ul className="trainingDay_duration_list">

                                                                    {step.goals.map((goal, index) => (
                                                                        <>
                                                                            <li
                                                                                key={goal.duration}
                                                                                className="trainingDay_data_box_duration_goal_list_item"
                                                                            >
                                                                                <div
                                                                                    className="noFractionne_duration_intensity">
                                                                                    {
                                                                                        goal.duration === " - " ?
                                                                                            <IonText
                                                                                                className="trainingDay_data_box_duration_content_ionText">
                                                                                                <p className="trainingDay_data_box_duration_content_duration">
                                                                                                    {goal.duration}
                                                                                                </p>
                                                                                            </IonText>

                                                                                            :

                                                                                            <>
                                                                                                {
                                                                                                    goal.duration === "00:00" ?
                                                                                                        <IonText
                                                                                                            className="trainingDay_data_box_duration_content_ionText">
                                                                                                            <p className="trainingDay_data_box_duration_content_duration">
                                                                                                                ?
                                                                                                            </p>
                                                                                                        </IonText>
                                                                                                        :
                                                                                                        <IonText
                                                                                                            className="trainingDay_data_box_duration_content_ionText">
                                                                                                            <p className="trainingDay_data_box_duration_content_duration">
                                                                                                                {
                                                                                                                    goal.duration.split(":").length === 2 ? <>{goal.duration.split(":")[0] === "00" ? "" : goal.duration.split(":")[0] + "min"}{goal.duration.split(":")[1] === "00" ? "" : goal.duration.split(":")[1]}{goal.duration.split(":")[0] === "00" ? "sec" : ""}</> :
                                                                                                                        <>
                                                                                                                            {goal.duration.split(":")[0] === "00" ? "" : goal.duration.split(":")[0] + "h"}{goal.duration.split(":")[1] === "00" ? "" : goal.duration.split(":")[1] +"min"}{goal.duration.split(":")[0] === "00" ? "sec" : ""}
                                                                                                                        </>

                                                                                                                }


                                                                                                            </p>
                                                                                                        </IonText>
                                                                                                }
                                                                                            </>


                                                                                    }

                                                                                </div>
                                                                                <>

                                                                                </>
                                                                            </li>

                                                                        </>
                                                                    ))}


                                                                </ul>


                                                                <ul className="noFractionne_list_webhook">
                                                                    {
                                                                        !isEmpty(step.done) && (
                                                                            step.done.map(res => (
                                                                                <>
                                                                                    {
                                                                                        !isEmpty(res.duration) && (
                                                                                            <>
                                                                                                {
                                                                                                    res.duration.split(":").length === 2 ?
                                                                                                        <IonText
                                                                                                            className="trainingDay_data_box_duration_content_ionText_notsession">
                                                                                                            <p className={res.success ? "trainingDay_recovery_intensity_success " : "trainingDay_recovery_intensity_notsuccess"}>
                                                                                                                ({res.duration.split(":")[0] + "min" + (res.duration.split(":")[1] === "00" ? res.duration.split(":")[1] + "sec" : res.duration.split(":")[1])})
                                                                                                            </p>
                                                                                                        </IonText> :


                                                                                                        <IonText
                                                                                                            className="trainingDay_data_box_duration_content_ionText_notsession">
                                                                                                            {
                                                                                                                res.duration === "00:00:00" ?
                                                                                                                    <p className={res.success ? "trainingDay_recovery_intensity_success " : "trainingDay_recovery_intensity_notsuccess"}>({res.duration})</p> :
                                                                                                                    <p className={res.success ? "trainingDay_recovery_intensity_success " : "trainingDay_recovery_intensity_notsuccess"}>
                                                                                                                        ({res.duration.split(":").length === 3 && res.duration.split(":")[0] !== "00" ? res.duration.split(":")[0] + 'h' + res.duration.split(":")[1] + "min" + res.duration.split(":")[2] : res.duration.split(":")[1] + "min" + res.duration.split(":")[2]})
                                                                                                                    </p>

                                                                                                            }


                                                                                                        </IonText>
                                                                                                }

                                                                                            </>)
                                                                                    }
                                                                                    {
                                                                                        isEmpty(res.duration) && (

                                                                                            <div className="fractionne_basse">
                                                                                                <>
                                                                                                    {


                                                                                                        res.segment_time_list_intensity[0].split(":").length === 2 ?
                                                                                                            <>
                                                                                                                <IonText
                                                                                                                    className="trainingDay_data_box_duration_content_ionText">
                                                                                                                    <p className={res.segment_success_list_intensity[0] ? "trainingDay_recovery_intensity_success " : "trainingDay_recovery_intensity_notsuccess"}>
                                                                                                                        ({res.segment_time_list_intensity[0].split(":")[0] === "00" ? "" : res.segment_time_list_intensity[0].split(":")[0] + "min"}{res.segment_time_list_intensity[0].split(":")[0] === "00" ? res.segment_time_list_intensity[0].split(":")[1] + 'sec' : res.segment_time_list_intensity[0].split(":")[1]})
                                                                                                                    </p>
                                                                                                                </IonText>
                                                                                                                <IonText
                                                                                                                    className="trainingDay_data_box_duration_content_ionText">
                                                                                                                    <p className={res.segment_success_list_recovery[0] ? "trainingDay_recovery_intensity_success " : "trainingDay_recovery_intensity_notsuccess"}>
                                                                                                                        ({res.segment_time_list_recovery[0].split(":")[0] === "00" ? "" : res.segment_time_list_recovery[0].split(":")[0] + "min"}{res.segment_time_list_recovery[0].split(":")[1] === "00" ? "" : res.segment_time_list_recovery[0].split(":")[1] + 'sec'}) </p>
                                                                                                                </IonText> </> :
                                                                                                            <>
                                                                                                                <IonText
                                                                                                                    className="trainingDay_data_box_duration_content_ionText">
                                                                                                                    <p className={res.segment_success_list_intensity[0] ? "trainingDay_recovery_intensity_success " : "trainingDay_recovery_intensity_notsuccess"}>
                                                                                                                        ({res.segment_time_list_intensity[0].split(":")[0] === "00" ? "" : res.segment_time_list_intensity[0].split(":")[0]}{res.segment_time_list_intensity[0].split(":")[1] === "00" ? "" : res.segment_time_list_intensity[0].split(":")[1] + "min"}{res.segment_time_list_intensity[0].split(":")[2] === "00" ? "" : res.segment_time_list_intensity[0].split(":")[2]})
                                                                                                                    </p>
                                                                                                                </IonText>
                                                                                                                <IonText
                                                                                                                    className="trainingDay_data_box_duration_content_ionText">
                                                                                                                    <p className={res.segment_success_list_recovery[0] ? "trainingDay_recovery_intensity_success " : "trainingDay_recovery_intensity_notsuccess"}>
                                                                                                                        ({res.segment_time_list_recovery[0].split(":")[0] === "00" ? "" : res.segment_time_list_recovery[0].split(":")[0]}{res.segment_time_list_recovery[0].split(":")[1] === "00" ? "" : res.segment_time_list_recovery[0].split(":")[1] + "min"}{res.segment_time_list_recovery[0].split(":")[2] === "00" ? "" : res.segment_time_list_recovery[0].split(":")[2]}) </p>
                                                                                                                </IonText>
                                                                                                            </>
                                                                                                    }

                                                                                                </>


                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </>
                                                                            ))
                                                                        )
                                                                    }
                                                                </ul>

                                                            </div>


                                                            <div className="noFractionne_box_right">
                                                                <ul className="trainingDay_data_box_duration_label_list">
                                                                    {step.goals.map((goal, index) => (
                                                                        <>
                                                                            <li key={goal.label}>
                                                                                <div className="noFractionne_duration">
                                                                                    {
                                                                                        x.label === 'Session' ?
                                                                                            <>
                                                                                                {
                                                                                                    index % 2 ? <img
                                                                                                            className="trainingDay_recovery_intensity_img"
                                                                                                            src={logoIntensity}
                                                                                                            alt="intensity"/> :
                                                                                                        <img
                                                                                                            className="trainingDay_recovery_intensity_img"
                                                                                                            src={logoRecovery}
                                                                                                            alt="intensity"/>
                                                                                                }
                                                                                            </>
                                                                                            : ""
                                                                                    }

                                                                                    <IonText>
                                                                                        <p className="trainingDay_data_box_duration_label_text">
                                                                                            {goal.label.split("min/km")}
                                                                                        </p>
                                                                                    </IonText>

                                                                                    <>
                                                                                        {
                                                                                            !isEmpty(step.done) && (

                                                                                                step.done.map(res => (
                                                                                                    <>
                                                                                                        {
                                                                                                            !isEmpty(res.duration) && (
                                                                                                                <IonText
                                                                                                                    className="trainingDay_data_box_duration_content_ionText">
                                                                                                                    <p className={res.success ? "trainingDay_recovery_intensity_success " : "trainingDay_recovery_intensity_notsuccess"}>
                                                                                                                        ({res.avg_pace_done.split(":")[0] + ":" + res.avg_pace_done.split(":")[1]})
                                                                                                                    </p>
                                                                                                                </IonText>)
                                                                                                        }

                                                                                                    </>
                                                                                                ))
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                </div>
                                                                            </li>


                                                                        </>
                                                                    ))}
                                                                </ul>
                                                                <ul>
                                                                    <li className="fractionne_basse_list_item">
                                                                        {
                                                                            !isEmpty(x.steps[0].done) && (

                                                                                <>

                                                                                    {
                                                                                        isEmpty(x.steps[0].done[0].duration) && (

                                                                                            <div
                                                                                                className="fractionne_basse_right">

                                                                                                <IonText
                                                                                                    className="trainingDay_data_box_duration_content_ionText">
                                                                                                    <p className={step.done[0].success ? "trainingDay_recovery_intensity_success " : "trainingDay_recovery_intensity_notsuccess"}>
                                                                                                        {step.done[0].segment_avg_speed_list_intensity[0]}
                                                                                                    </p>
                                                                                                </IonText>
                                                                                                <IonText
                                                                                                    className="trainingDay_data_box_duration_content_ionText">
                                                                                                    <p className={step.done[0].success ? "trainingDay_recovery_intensity_success " : "trainingDay_recovery_intensity_notsuccess"}>
                                                                                                        {step.done[0].segment_avg_speed_list_recovery[0]}
                                                                                                    </p>
                                                                                                </IonText>


                                                                                            </div>

                                                                                        )
                                                                                    }
                                                                                </>)


                                                                        }
                                                                    </li>


                                                                </ul>
                                                            </div>


                                                        </div>
                                                    </li>
                                                </>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </div>
            </ul>

        </div>
    );
};

export default NoFractionne;