import React, {useEffect} from "react";
import {
    IonLabel,
    IonSkeletonText,
    IonItem,
    IonList,
    IonListHeader,
    IonAvatar,
    IonThumbnail,
    IonContent,
} from "@ionic/react";
import axios from "axios";
import {useSelector} from "react-redux";

const SkeletonObjectif = ({setQuestions}) => {

    const user = useSelector(state => state.currentUser)


        return (
            <IonContent>
                <>
                    <div className="typeOfRun_logo">
                        <IonSkeletonText
                            animated
                            style={{width: "100%", margin: "30px auto", height: "200px"}}
                        />
                    </div>

                    <div className="typeOfRun_textContent">
                        <p>
                            <IonSkeletonText
                                animated
                                style={{width: "100%"}}
                            ></IonSkeletonText>
                        </p>

                        <p>
                            <IonSkeletonText
                                animated
                                style={{width: "100%"}}
                            ></IonSkeletonText>
                        </p>
                        <p>
                            <IonSkeletonText
                                animated
                                style={{width: "100%"}}
                            ></IonSkeletonText>
                        </p>
                        <p>
                            <IonSkeletonText
                                animated
                                style={{width: "100%"}}
                            ></IonSkeletonText>
                        </p>
                        <p>
                            <IonSkeletonText
                                animated
                                style={{width: "100%"}}
                            ></IonSkeletonText>
                        </p>
                    </div>

                    <ul className="typeOfRun_list">
                        <li>
                            <IonSkeletonText
                                animated
                                style={{height: "50px"}}
                            ></IonSkeletonText>
                        </li>
                        <li>
                            <IonSkeletonText
                                animated
                                style={{height: "50px"}}
                            ></IonSkeletonText>
                        </li>
                        <li>
                            <IonSkeletonText
                                animated
                                style={{height: "50px"}}
                            ></IonSkeletonText>
                        </li>
                        <li>
                            <IonSkeletonText
                                animated
                                style={{height: "50px"}}
                            ></IonSkeletonText>
                        </li>
                        <li>
                            <IonSkeletonText
                                animated
                                style={{height: "50px"}}
                            ></IonSkeletonText>
                        </li>
                    </ul>
                </>
            </IonContent>
        );
    }
;

export default SkeletonObjectif;
