import React, {useState, useEffect} from 'react';
import {IonIcon, IonText} from "@ionic/react";
import {isEmpty} from "../../../../../../Utils/Utils";
import {chevronDown, chevronForward} from "ionicons/icons";
import logoIntensity from "../../../../../../assets/pictoFlit/intensite.png";
import logoRecovery from "../../../../../../assets/pictoFlit/recuperation.png";
import './vmaLength.css'

const VmaLength = ({c, repeat}) => {


    const [vmaLenght, setVmaLength] = useState([])
    const [showList, setShowList] = useState(false)
    const [json, setJson] = useState([])
    const [jsonRight, setJsonRight] = useState([])
    const [recovery, setRecovery] = useState([])
    const [recoveryRight, setRecoveryRight] = useState([])


    useEffect(() => {


        if (c.session_information.session[1].steps.length > 1) {

        }
        for (let i = 0; i < c.session_information.session[1].steps.length; i++) {

            for (let j = 0; j < c.session_information.session[1].steps[i].repeat; j++) {
                for (let x = 0; x < c.session_information.session[1].steps[i].goals.length; x++) {
                    setVmaLength(current => [...current, {


                        duration: c.session_information.session[1].steps[i].goals[x].duration,
                        label: c.session_information.session[1].steps[i].goals[x].label,
                        title: c.session_information.session[1].steps[i].goals[x].title,
                        repeat: c.session_information.session[1].steps[i].repeat
                    }


                    ])
                }


            }


        }

        if (!isEmpty(c.session_information.session[1].steps[0].done)) {


            for (let i = 0; i < c.session_information.session[1].steps.length; i++) {
                for (let j = 0; j < c.session_information.session[1].steps[i].repeat; j++) {

                    if (c.session_information.session[1].steps[i].goals[0].title === "Récupération inter-block") {
                        setJson(current => [...current, {
                            intensity: c.session_information.session[1].steps[i].done[0].duration,
                            intensity_success: c.session_information.session[1].steps[i].done[0].success,

                        }])
                        setJsonRight(current => [...current, {
                            intensity: c.session_information.session[1].steps[i].done[0].avg_pace_done,
                            intensity_success: c.session_information.session[1].steps[i].done[0].success,

                        }])
                    } else {

                        if (isEmpty(c.session_information.session[1].steps[i].done[0].segment_duration_success_list_intensity || c.session_information.session[1].steps[i].done[0].segment_duration_success_list_recovery)) {
                            setJson(current => [...current, {
                                intensity: c.session_information.session[1].steps[i].done[0].segment_time_list_intensity[j],
                                intensity_success: c.session_information.session[1].steps[i].done[0].segment_success_list_intensity[j],
                                recovery: c.session_information.session[1].steps[i].done[0].segment_time_list_recovery[j],
                                recovery_success: c.session_information.session[1].steps[i].done[0].segment_success_list_recovery[j]

                            }])

                            setJsonRight(current => [...current, {
                                intensity: c.session_information.session[1].steps[i].done[0].segment_avg_speed_list_intensity[j],
                                intensity_success: c.session_information.session[1].steps[i].done[0].segment_success_list_intensity[j],
                                recovery: c.session_information.session[1].steps[i].done[0].segment_avg_speed_list_recovery[j],
                                recovery_success: c.session_information.session[1].steps[i].done[0].segment_success_list_recovery[j]

                            }])
                        } else {


                            setJson(current => [...current, {
                                intensity: c.session_information.session[1].steps[i].done[0].segment_time_list_intensity[j],
                                intensity_success: c.session_information.session[1].steps[i].done[0].segment_duration_success_list_intensity[j],
                                recovery: c.session_information.session[1].steps[i].done[0].segment_time_list_recovery[j],
                                recovery_success: c.session_information.session[1].steps[i].done[0].segment_duration_success_list_recovery[j]

                            }])

                            setJsonRight(current => [...current, {
                                intensity: c.session_information.session[1].steps[i].done[0].segment_avg_speed_list_intensity[j],
                                intensity_success: c.session_information.session[1].steps[i].done[0].segment_success_list_intensity[j],
                                recovery: c.session_information.session[1].steps[i].done[0].segment_avg_speed_list_recovery[j],
                                recovery_success: c.session_information.session[1].steps[i].done[0].segment_success_list_recovery[j]

                            }])
                        }
                    }
                }


            }


        }


    }, [c.session_information.session])


    return (<div>
        <ul className="trainingDay_globalList">
            <li>

                <div className="span_test"></div>
            </li>
            <div className="trainingDay_content_box">
                {c.session_information.session.map((x, index) => (<li key={index}>
                    <div className="trainingDay_data_box">
                        <div
                            className={x.label === "Session" ? "trainingDay_data_box_duration" : "trainingDay_data_box_duration_white"}
                        >
                            <div className="trainingDay_boxTitle">
                                <div className="trainingDay_label_point_training"></div>

                                <IonText>
                                    <div className="trainingDay_data_box_duration_title">
                                        {x.label === "Session" ? <p>Séance</p> : <p> {x.label}</p>}
                                    </div>
                                </IonText>


                                {x.label === "Echauffement" && (<IonText>
                                    <p className="trainingDay_data_box_duration_allure">
                                        Allure (min/km)
                                    </p>
                                </IonText>)}
                            </div>
                            <div>
                                {x.label !== "Session" ? <div>
                                        <ul className="vmaLenght_echauffement_calm_list">
                                            <div className="vmaLenght_echauffement_calm_list_box_left">
                                                <li className="vmaLenght_echauffement_calm_list_item">
                                                    <p className="vmaLenght_echauffement_calm_list_nowebhook">
                                                        {x.steps[0].goals[0].duration.split(":").length === 2 && x.steps[0].goals[0].duration.split(":")[0] !== "00" ? x.steps[0].goals[0].duration.split(":")[0] + "min" + (x.steps[0].goals[0].duration.split(":")[1] === "00" ? "" : x.steps[0].goals[0].duration.split(":")[1]) : x.steps[0].goals[0].duration.split(":")[1] + "s"}
                                                    </p>
                                                </li>
                                                <li className="vmaLenght_echauffement_calm_list_item">
                                                    {!isEmpty(x.steps[0].done) && (
                                                        <p className={x.steps[0].done[0].success_duration === true ? "vmaLength_time_webhook_success_echauffement" : "vmaLength_time_webhook_nosuccess_echauffement"}>({

                                                            x.steps[0].done[0].duration.split(":").length === 2 && x.steps[0].done[0].duration.split(":")[0] !== "00" ? x.steps[0].done[0].duration.split(":")[0] + "min" + (x.steps[0].done[0].duration.split(":")[1] === "00" ? "" : x.steps[0].done[0].duration.split(":")[1]) : x.steps[0].done[0].duration.split(":")[1] + "s"


                                                        })</p>)}
                                                </li>
                                            </div>
                                            <div className=" vmaLenght_echauffement_calm_list_box_right">
                                                <li className=" vmaLenght_echauffement_calm_list_item">
                                                    <p className=" vmaLenght_echauffement_calm_list__allure_nowebhook">{x.steps[0].goals[0].label.split("min/km")}</p>
                                                </li>
                                                <li className=" vmaLenght_echauffement_calm_list_item">
                                                    {!isEmpty(x.steps[0].done) && (
                                                        <p className={x.steps[0].done[0].success ? "vmaLength_time_webhook_success_echauffement" : "vmaLength_time_webhook_nosuccess_echauffement"}>({x.steps[0].done[0].avg_pace_done})</p>)}
                                                </li>
                                            </div>

                                        </ul>

                                    </div> :


                                    <div className="vmaLength_session_box">

                                        <div
                                            className={c.session_information.session[1].steps[0].repeat <= 1 ? "trainingDay_data_box_duration_content_repeat" : "trainingDay_data_box_duration_content"}
                                        >


                                            {

                                                x.label === "Session" && showList === true ? <>{

                                                    showList ? <IonIcon className="trainingDay_chevron"
                                                                        onClick={() => setShowList(!showList)}
                                                                        icon={chevronDown}/> :
                                                        <IonIcon className="trainingDay_chevron"
                                                                 onClick={() => setShowList(!showList)}
                                                                 icon={chevronForward}/>} </> : ""

                                            }
                                        </div>

                                        <div
                                            className={showList ? "vmaLength_session_box_content" : "vmaLength_session_box_content_noDetails"}>
                                            {showList ?

                                                <>


                                                    <div className="vmaLength_session_box_left">


                                                        <ul className="vmaLength_session_box_left_list">
                                                            {vmaLenght.map((m, index) => <>
                                                                <li>
                                                                    <p className="vmaLength_box_left_nowebhook">{m.duration.split(":")[0] === "00" ? m.duration.split(":")[1] + "s" : m.duration.split(":")[0] + "min" + (m.duration.split(":")[1] !== "00" ? m.duration.split(":")[1] : "")}</p>
                                                                </li>

                                                            </>)}
                                                        </ul>
                                                        {!isEmpty(c.session_information.session[1].steps[0].done) && (
                                                            <ul className="vmaLength_session_box_time_webhook">
                                                                {/*{c.session_information.session[1].steps.map(s =>s).map(m => m.goals[0].title  === "Récupération inter-block")  ? <p>{recovery[0].intensity}</p>  :*/}
                                                                <>
                                                                    {json.map(m => <>
                                                                        <li><p
                                                                            className={m.intensity_success ? "vmaLength_time_webhook_success" : "vmaLength_time_webhook_nosuccess"}>({m.intensity.split(":")[0] === "00" ? m.intensity.split(":")[1] + "s" : m.intensity.split(":")[0] + "min" + (m.intensity.split(":")[1] !== "00" ? m.intensity.split(":")[1] : "")})</p>
                                                                        </li>

                                                                        {
                                                                            m.recovery && (
                                                                                <li><p
                                                                                    className={m.intensity_success ? "vmaLength_time_webhook_success" : "vmaLength_time_webhook_nosuccess"}>({m.recovery.split(":")[0] === "00" ? m.recovery.split(":")[1] + "s" : m.recovery.split(":")[0] + "min" + (m.recovery.split(":")[1] !== "00" ? m.recovery.split(":")[1] : "")})</p>
                                                                                </li>
                                                                            )
                                                                        }
                                                                    </>)}
                                                                </>
                                                                {/*}*/}
                                                            </ul>)}
                                                    </div>

                                                    <div className="vmaLength_session_box_right">

                                                        <ul>
                                                            {vmaLenght.map((m, index) => <>
                                                                <li>
                                                                    <div
                                                                        className="vmaLength_box_right_content_flitou">
                                                                        {
                                                                            m.title === "Récupération inter-block" ?
                                                                                <img
                                                                                    className="trainingDay_recovery_intensity_img"
                                                                                    src={logoIntensity}
                                                                                    alt="intensity"/> :
                                                                                <>
                                                                                    {index % 2 ? <img
                                                                                        className="trainingDay_recovery_intensity_img"
                                                                                        src={logoIntensity}
                                                                                        alt="intensity"/> : <img
                                                                                        className="trainingDay_recovery_intensity_img"
                                                                                        src={logoRecovery}
                                                                                        alt="intensity"/>
                                                                                    }</>
                                                                        }


                                                                        <p className="vmaLength_box_right_nowebhook">{m.label.split("min/km")}</p>
                                                                    </div>


                                                                </li>

                                                            </>)}
                                                        </ul>

                                                        {!isEmpty(c.session_information.session[1].steps[0].done) && (
                                                            <ul className="vmaLength_session_box_time_webhook">
                                                                {/*{c.session_information.session[1].steps.map(s => s).map(m => m.goals[0].title  === "Récupération inter-block")  ? <p>{recoveryRight[0].intensity}</p> :*/}
                                                                <>
                                                                    {jsonRight.map(m =>
                                                                        <>
                                                                            <li><p
                                                                                className={m.intensity_success ? "vmaLength_time_webhook_success" : "vmaLength_time_webhook_nosuccess"}>({m.intensity})</p>
                                                                            </li>
                                                                            {
                                                                                m.recovery && (
                                                                                    <li><p
                                                                                        className={m.recovery_success ? "vmaLength_time_webhook_success" : "vmaLength_time_webhook_nosuccess"}>({m.recovery})</p>
                                                                                    </li>
                                                                                )
                                                                            }
                                                                        </>
                                                                    )}
                                                                </>
                                                                {/*}*/}
                                                            </ul>)}
                                                    </div>

                                                </> : <>


                                                    <div className="vmaLength_session_box_noDetails">


                                                        <>
                                                            {c.session_information.session[1].steps.map((m, index) =>

                                                                <div className="box_test">
                                                                    <div className="box_title">


                                                                        <IonText>
                                                                            <p className={showList || m.repeat === 1 ? "repeat_none" : "box_repeat"}>
                                                                                {" "}
                                                                                {m.repeat} fois
                                                                            </p>
                                                                        </IonText>

                                                                        {index === 0 ? <>

                                                                            {

                                                                                showList && index === 0 ?
                                                                                    <IonIcon
                                                                                        className="trainingDay_chevron"
                                                                                        onClick={() => setShowList(!showList)}
                                                                                        icon={chevronDown}/> :
                                                                                    <IonIcon
                                                                                        className="trainingDay_chevron"
                                                                                        onClick={() => setShowList(!showList)}
                                                                                        icon={chevronForward}/>
                                                                            }
                                                                        </> : ""}

                                                                    </div>

                                                                    <div className="box_content">

                                                                        <>{m.goals.map((g, index) => <div
                                                                            className="box_global">
                                                                            <div
                                                                                className="box_left">
                                                                                <p className="vmaLength_box_left_nowebhook">{g.duration.split(":")[0] === "00" ? g.duration.split(":")[1] + "s" : g.duration.split(":")[0] + "min" + (g.duration.split(":")[1] !== "00" ? g.duration.split(":")[1] : "")}</p>

                                                                                {!isEmpty(c.session_information.session[1].steps[0].done) && (

                                                                                    <>
                                                                                        {m.goals[0].title === "Récupération inter-block" ?
                                                                                            <p className={m.done[0].success ? "vmaLength_time_webhook_success noscroll" : "vmaLength_time_webhook_nosuccess noscroll"}>({!isEmpty(m.done[0].duration) && ((m.done[0].duration.split(":")[0] === "00" ? m.done[0].duration.split(":")[1] + "s" : m.done[0].duration.split(":")[0] + "min" + (m.done[0].duration.split(":")[1] !== "00" ? m.done[0].duration.split(":")[1] : "")))})</p> : <>

                                                                                                {index === 0 ?

                                                                                                    <p className={m.done[0].intensity_success ? "vmaLength_time_webhook_success noscroll" : "vmaLength_time_webhook_nosuccess noscroll"}>({!isEmpty(m.done[0].intensity_avg_time) && ((m.done[0].intensity_avg_time.split(":")[0] === "00" ? m.done[0].intensity_avg_time.split(":")[1] + "s" : m.done[0].intensity_avg_time.split(":")[0] + "min" + (m.done[0].intensity_avg_time.split(":")[1] !== "00" ? m.done[0].intensity_avg_time.split(":")[1] : "")))})</p> :
                                                                                                    <p className={m.done[0].recovery_success ? "vmaLength_time_webhook_success noscroll" : "vmaLength_time_webhook_nosuccess noscroll"}>({!isEmpty(m.done[0].recovery_avg_time) && ((m.done[0].recovery_avg_time.split(":")[0] === "00" ? m.done[0].recovery_avg_time.split(":")[1] + "s" : m.done[0].recovery_avg_time.split(":")[0] + "min" + (m.done[0].recovery_avg_time.split(":")[1] !== "00" ? m.done[0].recovery_avg_time.split(":")[1] : "")))})</p>}
                                                                                            </>}
                                                                                    </>)}
                                                                            </div>
                                                                            <div
                                                                                className="box_right">
                                                                                {g.title === "Récupération inter-block" ?

                                                                                    <img
                                                                                        className="trainingDay_recovery_intensity_img"
                                                                                        src={logoIntensity}
                                                                                        alt="intensity"/> : <>
                                                                                        {index % 2 ? <img
                                                                                                className="trainingDay_recovery_intensity_img"
                                                                                                src={logoIntensity}
                                                                                                alt="intensity"/> :
                                                                                            <img
                                                                                                className="trainingDay_recovery_intensity_img"
                                                                                                src={logoRecovery}
                                                                                                alt="intensity"/>}
                                                                                    </>}

                                                                                <p className="box_right_label">{g.label.split("min/km")} </p>
                                                                                {!isEmpty(c.session_information.session[1].steps[0].done) && (<>
                                                                                    {m.goals[0].title === "Récupération inter-block" ?
                                                                                        <p className={m.done[0].success ? "vmaLength_time_webhook_success" : "vmaLength_time_webhook_nosuccess"}>({m.done[0].avg_pace_done})</p> : <>

                                                                                            {index === 0 ?
                                                                                                <p className={m.done[0].intensity_success ? "vmaLength_time_webhook_success" : "vmaLength_time_webhook_nosuccess"}>({m.done[0].intensity_avg_speed})</p> :
                                                                                                <p className={m.done[0].recovery_success ? "vmaLength_time_webhook_success" : "vmaLength_time_webhook_nosuccess"}>({m.done[0].recovery_avg_speed})</p>}
                                                                                        </>}
                                                                                </>)}
                                                                            </div>


                                                                        </div>)}

                                                                        </>

                                                                    </div>
                                                                </div>)}
                                                        </>


                                                    </div>


                                                </>}
                                        </div>


                                    </div>}
                            </div>
                        </div>
                    </div>
                </li>))}
            </div>
        </ul>
    </div>);
};

export default VmaLength;