import React, {useEffect, useState} from "react";
import {IonContent, IonHeader, IonPage, IonSkeletonText, IonToolbar} from "@ionic/react";
import "./skeletonTrainingWeek.css";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {ChangeLogin} from "../../../../redux/slices/currentUser/currentUserSlice";
import {emptyTraining, getTraining} from "../../../../redux/slices/trainingData/trainingDataSlice";
import {isEmpty} from "../../../../Utils/Utils";
import {emptyGoals} from "../../../../redux/slices/goals/goalSlice";
import {forEachGoal} from "../../../../API/Goal/GetGoal";
import {forEachTraining} from "../../../../API/Training/GetTraining";
import logoBlanc from "../../../../assets/pictoFlit/LOGO_FLIT_blanc2.png";
import chaussure from "../../../../assets/menu/chaussure_bleu.png";
import moment from "moment/moment";


const SkeletonTrainingWeek = () => {
    const user = useSelector((state) => state.currentUser);
    const dispatch =useDispatch()
    const goal = useSelector(state => state.goal)




    return (

        <IonPage>
            <IonHeader>
            <IonToolbar className="tab1_header">
                <div className="tab1_content">
                    <img className="tab1_logo" src={logoBlanc} alt="logo"/>
                    <div className={"tab1_progressBar_objectiv"}>
                        <div className={"tab1_progressBar_objectiv_icon_box"}>
                            <img className={"tab1_progressBar_objectiv_icon"} src={chaussure}
                                 alt={"logo_chaussure"}/>
                            <div>{!isEmpty(goal[0]) && (
                                <>
                                    {
                                        goal[0].filter(f => f.main === true).map((f, index) => (
                                            <div key={index}>
                                                {
                                                    index === 0 && (
                                                        <p>   {
                                                            f.name === "Semi-marathon" ? "21k" : f.name === "Marathon" ? "42k" : f.name === "Pas d'objectif" ? "?" : f.name
                                                        }
                                                        </p>
                                                    )
                                                }
                                            </div>

                                        ))
                                    }


                                </>
                            )}</div>
                        </div>

                        {
                            !isEmpty(goal[0]) && (
                                <>
                                    {
                                        goal[0].filter(f => f.main === true).map((f, index) => (
                                            <div key={index}>
                                                {
                                                    index === 0 && (
                                                        <p>{moment(f.date).format(('l'))}</p>
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                </>
                            )
                        }

                        {/*     <p>{moment(goal.filter(f => f.main === true)[0].date).format(('l'))}</p>*/}
                    </div>
                </div>


            </IonToolbar>
        </IonHeader>
            <IonContent>
                <>
                    <div className="skeletonWeekPlan_title">
                        <h3 className="skeletonWeekPlan_title_text">
                            <IonSkeletonText
                                animated
                                style={{
                                    width: "80%",
                                    margin: "auto",
                                    height: "30px",
                                }}
                            ></IonSkeletonText>
                        </h3>
                        <h3 className="skeletonWeekPlan_title_text">
                            <IonSkeletonText
                                animated
                                style={{
                                    width: "80%",
                                    margin: "auto",
                                    height: "30px",
                                }}
                            ></IonSkeletonText>
                        </h3>

                        <h3 className="skeletonWeekPlan_cycle">
                            <IonSkeletonText
                                animated
                                style={{
                                    width: "80%",
                                    margin: "auto",
                                    height: "30px",
                                }}
                            ></IonSkeletonText>
                        </h3>
                    </div>

                    <ul className="skeletonWeekPlan_infoList">
                        <li className="skeletonWeekPlan_infoList_item">
                            <IonSkeletonText
                                animated
                                style={{
                                    width: "80%",
                                    height: "10px",
                                }}
                            ></IonSkeletonText>
                        </li>
                        <li className="skeletonWeekPlan_infoList_item">
                            <IonSkeletonText
                                animated
                                style={{
                                    width: "80%",
                                    height: "10px",
                                }}
                            ></IonSkeletonText>
                        </li>
                        <li className="skeletonWeekPlan_infoList_item">
                            <IonSkeletonText
                                animated
                                style={{
                                    width: "80%",
                                    height: "10px",
                                }}
                            ></IonSkeletonText>
                        </li>
                    </ul>

                    <ul className="skeletonWeekPlan_list">
                        <li>
                            <IonSkeletonText
                                animated
                                style={{
                                    width: "90%",
                                    height: "80px",
                                    margin: "10px auto",
                                }}
                            ></IonSkeletonText>
                        </li>
                        <li>
                            <IonSkeletonText
                                animated
                                style={{
                                    width: "90%",
                                    height: "80px",
                                    margin: "10px auto",
                                }}
                            ></IonSkeletonText>
                        </li>
                        <li>
                            <IonSkeletonText
                                animated
                                style={{
                                    width: "90%",
                                    height: "80px",
                                    margin: "10px auto",
                                }}
                            ></IonSkeletonText>
                        </li>
                        <li>
                            <IonSkeletonText
                                animated
                                style={{
                                    width: "90%",
                                    height: "80px",
                                    margin: "10px auto",
                                }}
                            ></IonSkeletonText>
                        </li>
                    </ul>
                </>
            </IonContent>
        </IonPage>

    );
};

export default SkeletonTrainingWeek;
